.doka--root {
    --doka-effect--invert: 100%;
    --doka-color--alpha: #2990ff;
    --doka-color--alpha-dim: #1a80ec;
  
    --doka-effect--invert: 0%;
  
    --doka-value--alpha: calc(100% - var(--doka-effect--invert));
    --doka-value--beta: var(--doka-effect--invert);
  
    --doka-color--alpha: #ffd843;
    --doka-color--alpha-dim: #ffc343;
  
    --doka-font-size: 16px;
    --doka-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
  
    /* editor max dimensions */
    --doka-editor--max-width: 80em;
    --doka-editor--max-height: 60em;
  
    /* spacing between editor and buttons */
    --doka-editor--spacing: 0;
  
    /* default size and max size of modal */
    --doka-modal--width: 70vw;
    --doka-modal--height: 80vh;
    --doka-modal--max-width: 56rem;
    --doka-modal--max-height: 40rem;
  
    /* frost effect used on browsers that support backdrop-filter */
    --doka-effect--frost: blur(10px);
  
    /* color and border-radius values */
    --doka-color--beta: hsl(0, 0%, var(--doka-value--alpha));
    --doka-color--gamma: hsl(0, 0%, var(--doka-value--beta));
    --doka-color--delta: hsla(0, 0%, var(--doka-value--beta), 0.85);
    --doka-color--epsilon: hsla(0, 0%, var(--doka-value--beta), 0.75);
  
    --doka-editor--color: hsla(0, 0%, var(--doka-value--alpha), 0.9);
    --doka-editor--color-dim: hsla(0, 0%, var(--doka-value--alpha), 0.3);
    --doka-editor--background-color: hsla(0, 0%, var(--doka-value--beta), 0.99);
    --doka-editor--background-ellipse-inner-color: hsla(
      0,
      0%,
      var(--doka-value--alpha),
      0.15
    );
    --doka-editor--background-ellipse-outer-color: hsla(
      0,
      0%,
      var(--doka-value--alpha),
      0
    );
  
    --doka-modal--background: hsl(0, 0%, var(--doka-value--beta));
    --doka-modal--overlay: hsla(0, 0%, var(--doka-value--beta), 0.8);
    --doka-modal--shadow: 0 1em 4em rgba(0, 0, 0, 0.25),
      0 0.5em 2em rgba(0, 0, 0, 0.25);
  
    --doka-scrollbar-rail--color: var(--doka-color--delta);
    --doka-scrollbar-thumb--color: var(--doka-color--epsilon);
  
    --doka-component--color: var(--doka-editor--color);
    --doka-component--background: hsla(0, 0%, var(--doka-value--alpha), 0.15);
    --doka-component--background-dim: hsla(0, 0%, var(--doka-value--alpha), 0.1);
    --doka-component--background-dark: hsla(0, 0%, var(--doka-value--beta), 0.8);
    --doka-component--background-darker: hsla(
      0,
      0%,
      var(--doka-value--beta),
      0.9
    );
  
    --doka-component-alt--color: var(--doka-color--delta);
    --doka-component-alt--color-dim: hsla(0, 0%, var(--doka-value--beta), 0.75);
    --doka-component-alt--background: hsla(0, 0%, var(--doka-value--alpha), 0.85);
    --doka-component-alt-effect--background: hsla(
      0,
      0%,
      var(--doka-value--alpha),
      0.6
    );
    --doka-component-alt--edge: hsla(0, 0%, var(--doka-value--beta), 0.15);
    --doka-component-alt--shadow: 0 0.75em 1em -0.75em rgba(0, 0, 0, 0.4),
      0 1em 2em 0 rgba(0, 0, 0, 0.05);
  
    --doka-button--color: var(--doka-component--color);
    --doka-button--background: var(--doka-component--background);
    --doka-button--background-dim: var(--doka-component--background-dim);
    --doka-button-hover--background: hsla(0, 0%, var(--doka-value--alpha), 0.25);
  
    --doka-button-alt--color: var(--doka-component-alt--color);
    --doka-button-alt--background: var(--doka-component-alt--background);
    --doka-button-alt-hover--background: hsla(
      0,
      0%,
      var(--doka-value--alpha),
      0.95
    );
    --doka-button-alt-effect--background: var(
      --doka-component-alt-effect--background
    );
    --doka-button-alt-effect-hover--background: hsla(
      0,
      0%,
      var(--doka-value--alpha),
      0.75
    );
  
    --doka-button-primary--color: var(--doka-color--gamma);
    --doka-button-primary--background: var(--doka-color--alpha);
    --doka-button-primary-hover--background: var(--doka-color--alpha-dim);
  
    --doka-button-main--radius: 9999em;
    --doka-button-tab--radius: 0;
    --doka-button-tool--radius: 0;
  
    --doka-dropdown-hover--background: hsla(0, 0%, var(--doka-value--beta), 0.1);
    --doka-dropdown-hover-active--background: hsla(
      0,
      0%,
      var(--doka-value--beta),
      0.15
    );
    --doka-dropdown-selected--color: var(--doka-editor--color);
    --doka-dropdown-selected--background: hsla(
      0,
      0%,
      var(--doka-value--beta),
      0.9
    );
    --doka-dropdown-selected-hover--background: hsla(
      0,
      0%,
      var(--doka-value--beta),
      0.8
    );
    --doka-dropdown-selected-active-hover--background: hsla(
      0,
      0%,
      var(--doka-value--beta),
      0.6
    );
  
    --doka-range-input--background: hsla(0, 0%, var(--doka-value--alpha), 0.2);
    --doka-range-input-recenter--background: var(--doka-range-input--background);
    --doka-range-input-centered--background: var(--doka-range-input--background);
    --doka-range-input-thumb--color: hsl(0, 0%, var(--doka-value--alpha));
    --doka-range-input-thumb-hover--color: hsla(
      0,
      0%,
      var(--doka-value--alpha),
      0.15
    );
  
    --doka-tab--border-color: hsla(0, 0%, 0%, 0.15);
  
    --doka-crop-focal-line--color: hsla(0, 0%, 100%, 0.5);
    --doka-crop-corner--color: var(--doka-color--beta);
    --doka-crop-corner-focus--color: var(--doka-color--beta);
    --doka-crop-corner--shadow: 0 0.0625em 0.125em rgba(0, 0, 0, 0.2);
    --doka-crop-edge-focus--color: hsla(0, 0%, var(--doka-value--alpha), 0.5);
  
    --doka-image-outline--color: var(--doka-color--beta);
    --doka-image-overlay--color: var(--doka-color--gamma);
    --doka-image-focus--color: hsla(0, 0%, var(--doka-value--alpha), 0.25);
  
    --doka-status--background: hsla(0, 0%, var(--doka-value--alpha), 0.1);
  
    --doka-status-bubble--color: hsla(0, 0%, var(--doka-value--beta), 0.8);
    --doka-status-bubble--background: hsla(0, 0%, var(--doka-value--alpha), 0.75);
    --doka-status-bubble-effect--background: hsla(
      0,
      0%,
      var(--doka-value--alpha),
      0.5
    );
    --doka-status-bubble--shadow: 0 0.125em 1em rgba(0, 0, 0, 0.2);
  
    --doka-size-indicator--color: hsla(0, 0%, 0%, 0.85);
    --doka-size-indicator--background: hsla(0, 0%, 100%, 0.95);
    --doka-size-indicator--edge: hsla(0, 0%, 0%, 0.15);
    --doka-size-indicator--radius: 0.25em;
  
    --doka-button-zoom--color: hsla(0, 0%, 0%, 0.85);
    --doka-button-zoom--background: hsla(0, 0%, 100%, 0.85);
    --doka-button-zoom-hover--background: hsla(0, 0%, 100%, 0.95);
    --doka-button-zoom-effect--background: hsla(0, 0%, 100%, 0.6);
    --doka-button-zoom-effect-hover--background: hsla(0, 0%, 100%, 0.75);
  
    --doka-filter-tile--radius: 0;
    --doka-filter-tile-selected--color: var(--doka-color--alpha);

    .doka--view-stack {
        pointer-events: none;
      }
      .doka--view-stack > * {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        outline: transparent;
      }
      .doka--view-stack [data-view-active="false"] * {
        pointer-events: none !important;
      }
      .doka--view-stack [data-view-active="true"] {
        z-index: 1;
      }
      .doka--view-stack [data-view-hidden="true"] {
        display: none !important;
      }
      .doka--button-app {
        position: static;
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
        border: none;
        font-family: inherit;
        font-size: 1em;
        outline: transparent;
        color: hsla(0, 0%, 100%, 0.9);
        color: var(--doka-button--color);
        background-color: hsla(0, 0%, 100%, 0.15);
        background-color: var(--doka-button--background);
        border-radius: 9999em;
        border-radius: var(--doka-button-main--radius);
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        line-height: 2.5;
        padding: 0 1.5em;
        font-weight: 500;
        transition: transform 0.15s ease-out, background-color 0.25s ease-out,
          color 0.25s ease-out;
        transform: scale(1);
      }
      .doka--button-app svg {
        -ms-flex-positive: 1;
        flex-grow: 1;
        margin: 0 auto;
        display: inline-block;
      }
      .doka--button-app svg + span {
        margin-left: 0.5em;
      }
      .doka--button-app span {
        font-size: 0.875em;
      }
      .doka--button-app:focus,
      .doka--button-app:hover {
        background-color: hsla(0, 0%, 100%, 0.25);
        background-color: var(--doka-button-hover--background);
      }
      .doka--button-app:active {
        transition-duration: 0.25s;
        transform: scale(0.95);
      }
      @supports (
        (-webkit-backdrop-filter: var(--doka-effect--frost)) or
          (backdrop-filter: var(--doka-effect--frost))
      ) {
        .doka--button-app {
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: var(--doka-effect--frost);
          backdrop-filter: var(--doka-effect--frost);
        }
      }
      .doka--button-app.doka--button-icon-only {
        width: 2.25em;
        height: 2.25em;
        padding: 0;
      }
      .doka--button-action-confirm {
        color: #000;
        color: var(--doka-button-primary--color);
        background-color: #ffd843;
        background-color: var(--doka-button-primary--background);
        font-weight: 600;
        -webkit-backdrop-filter: none;
        backdrop-filter: none;
      }
      .doka--button-action-confirm:focus,
      .doka--button-action-confirm:hover {
        background-color: #ffc343;
        background-color: var(--doka-button-primary-hover--background);
      }
      .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
        .doka--button-app {
        font-size: 0.85em;
        -webkit-backdrop-filter: none;
        backdrop-filter: none;
      }
      .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
        .doka--button-app
        svg {
        width: 1.25em;
        height: 1.25em;
      }
      .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
        .doka--button-action-confirm {
        font-size: 0.75em;
      }
      .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
        .doka--button-app:not(.doka--button-action-confirm) {
        color: hsla(0, 0%, 100%, 0.9);
        color: var(--doka-button--color);
        background-color: transparent;
      }
      .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
        .doka--button-app.doka--button-icon-only
        svg {
        width: 1.75em;
        height: 1.75em;
      }
      .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
        .doka--button-app.doka--button-icon-fallback {
        width: 2.5em;
        height: 2.5em;
        padding: 0;
      }
      .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
        .doka--button-app.doka--button-icon-fallback
        span {
        position: absolute;
        opacity: 0;
        width: 1px;
        height: 1px;
        overflow: hidden;
      }
      .doka--button-icon-fallback svg {
        display: none;
      }
      .doka--button-icon-fallback svg + span {
        margin-left: 0;
      }
      .doka--root[data-style-viewport*="x-cramped"][data-style-viewport*="multi-util"]
        .doka--button-icon-fallback
        svg {
        display: block;
      }
      .doka--root[data-style-viewport*="x-cramped"][data-style-viewport*="multi-util"]
        .doka--button-icon-fallback
        svg
        + span {
        margin-left: 0;
      }
      .doka--button-icon-only {
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
      .doka--button-icon-only span {
        position: absolute;
        opacity: 0;
        width: 1px;
        height: 1px;
        overflow: hidden;
      }
      .doka--button-tab {
        position: static;
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
        border: none;
        font-family: inherit;
        font-size: 1em;
        outline: transparent;
        line-height: inherit;
        color: hsla(0, 0%, 100%, 0.9);
        color: var(--doka-button--color);
        border-radius: 0;
        border-radius: var(--doka-button-tab--radius);
        width: 5em;
        max-width: 5em;
        padding-top: 0.875em;
        padding-bottom: 0.75em;
        background-color: transparent;
        font-weight: 500;
        transition: transform 0.15s ease-out, background-color 0.25s ease-out,
          color 0.25s ease-out;
        transform: scale(1);
      }
      .doka--button-tab svg {
        display: inline;
        margin-bottom: 0.325em;
      }
      .doka--button-tab span {
        display: block;
        font-size: 0.875em;
      }
      .doka--button-tab:focus,
      .doka--button-tab:hover {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: var(--doka-effect--frost);
        backdrop-filter: var(--doka-effect--frost);
      }
      .doka--button-tab:focus,
      .doka--button-tab:hover,
      .doka--button-tab[data-active="true"] {
        background-color: hsla(0, 0%, 100%, 0.15);
        background-color: var(--doka-button--background);
      }
      .doka--button-tab[data-active="true"]:focus,
      .doka--button-tab[data-active="true"]:hover {
        background-color: hsla(0, 0%, 100%, 0.25);
        background-color: var(--doka-button-hover--background);
      }
      .doka--button-tab:active {
        transition-duration: 0.25s;
        transform: scale(0.95);
      }
      .doka--button-tab .doka--icon-resize-arrow-ne,
      .doka--button-tab .doka--icon-resize-arrow-sw {
        transition: opacity 0.25s ease-out;
      }
      .doka--button-tab[data-scale-direction="down"] .doka--icon-resize-arrow-ne {
        opacity: 0;
      }
      .doka--button-tab[data-scale-direction="down"] .doka--icon-resize-arrow-sw,
      .doka--button-tab[data-scale-direction="up"] .doka--icon-resize-arrow-ne {
        opacity: 1;
      }
      .doka--button-tab[data-scale-direction="up"] .doka--icon-resize-arrow-sw {
        opacity: 0;
      }
      @supports (
        (-webkit-backdrop-filter: var(--doka-effect--frost)) or
          (backdrop-filter: var(--doka-effect--frost))
      ) {
        .doka--button-tab[data-active="true"] {
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: var(--doka-effect--frost);
          backdrop-filter: var(--doka-effect--frost);
        }
      }
      .doka--utils[data-layout="compact"] .doka--button-tab {
        height: 4.6875em;
      }
      .doka--utils[data-layout="compact"] .doka--button-tab span {
        display: none;
      }
      .doka--utils[data-layout="compact"] .doka--button-tab svg {
        margin-bottom: 0;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--utils {
        padding: 0 1px;
      }
      .doka--root[data-style-viewport*="x-cramped"]
        .doka--utils[data-util-count="5"]
        .doka--button-tab {
        width: 20%;
      }
      .doka--root[data-style-viewport*="x-cramped"]
        .doka--utils[data-util-count="5"]
        .doka--button-tab
        span {
        display: none;
      }
      .doka--root[data-style-viewport*="x-cramped"]
        .doka--utils[data-util-count="4"]
        .doka--button-tab {
        width: 25%;
      }
      .doka--root[data-style-viewport*="x-cramped"]
        .doka--utils[data-util-count="3"]
        .doka--button-tab {
        width: 33.333%;
      }
      .doka--root[data-style-viewport*="x-cramped"]
        .doka--utils[data-util-count="2"]
        .doka--button-tab {
        width: 50%;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--button-tab {
        min-width: 2em;
        max-width: 3.5em;
        height: 3.25em;
        padding: 0.325em 0;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
        box-shadow: 0 0 0 1px var(--doka-tab--border-color);
      }
      .doka--root[data-style-viewport*="x-cramped"]
        .doka--button-tab:first-child:before,
      .doka--root[data-style-viewport*="x-cramped"]
        .doka--button-tab:last-child:before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        content: "";
        pointer-events: none;
      }
      .doka--root[data-style-viewport*="x-cramped"]
        .doka--button-tab:first-child:before {
        box-shadow: -1px 0 0 0 rgba(0, 0, 0, 0.15);
        box-shadow: -1px 0 0 0 var(--doka-tab--border-color);
      }
      .doka--root[data-style-viewport*="x-cramped"]
        .doka--button-tab:last-child:before {
        box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.15);
        box-shadow: 1px 0 0 0 var(--doka-tab--border-color);
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--button-tab svg {
        width: 1em;
        margin: 0 auto -0.125em;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--button-tab span {
        font-size: 0.5625em;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--button-tab:active {
        transform: scale(1);
      }
      .doka--root[data-style-viewport*="x-cramped"]
        .doka--button-tab[data-active="true"] {
        -webkit-backdrop-filter: none;
        backdrop-filter: none;
      }
      .doka--root[data-style-viewport*="x-cramped"]
        .doka--button-tab[data-active="true"]:focus,
      .doka--root[data-style-viewport*="x-cramped"]
        .doka--button-tab[data-active="true"]:hover {
        background-color: hsla(0, 0%, 100%, 0.15);
        background-color: var(--doka-button--background);
      }
      .doka--button-tool {
        position: static;
        width: auto;
        height: auto;
        margin: 0;
        border: none;
        font-family: inherit;
        font-size: 1em;
        font-weight: 400;
        outline: transparent;
        border-radius: 0;
        border-radius: var(--doka-button-tool--radius);
        color: hsla(0, 0%, 100%, 0.9);
        color: var(--doka-button--color);
        display: -ms-flexbox;
        display: flex;
        padding: 0 0.75em 0 0.5em;
        -ms-flex-align: center;
        align-items: center;
        background-color: transparent;
        transition: transform 0.15s ease-out, background-color 0.25s ease-out,
          color 0.25s ease-out;
        transform: scale(1);
      }
      .doka--button-tool span {
        font-size: 0.875em;
        margin-left: 0.5em;
      }
      .doka--button-tool:focus,
      .doka--button-tool:hover {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: var(--doka-effect--frost);
        backdrop-filter: var(--doka-effect--frost);
        background-color: hsla(0, 0%, 100%, 0.15);
        background-color: var(--doka-button--background);
      }
      .doka--button-tool:active {
        transition-duration: 0.25s;
        transform: scale(0.95);
      }
      [data-layout="compact"] .doka--button-tool {
        padding: 0 0.5em;
        border-radius: 0.25em;
        background-color: hsla(0, 0%, 100%, 0.1);
        background-color: var(--doka-button--background-dim);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: var(--doka-effect--frost);
        backdrop-filter: var(--doka-effect--frost);
        width: 2.5em;
        -ms-flex-positive: 0;
        flex-grow: 0;
      }
      [data-layout="compact"] .doka--button-tool:focus,
      [data-layout="compact"] .doka--button-tool:hover {
        background-color: hsla(0, 0%, 100%, 0.15);
        background-color: var(--doka-button--background);
      }
      [data-layout="compact"] .doka--button-tool span {
        position: absolute;
        opacity: 0;
        width: 1px;
        height: 1px;
        overflow: hidden;
      }
      .doka--button-zoom {
        position: static;
        margin: 0;
        padding: 0;
        border: none;
        font-family: inherit;
        font-size: 1em;
        outline: transparent;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        line-height: 2.5;
        font-weight: 500;
        transition: transform 0.15s ease-out, background-color 0.25s ease-out,
          color 0.25s ease-out;
        border-radius: 9999em;
        height: 2.5em;
        width: 2.5em;
        color: rgba(0, 0, 0, 0.85);
        color: var(--doka-button-zoom--color);
        background-color: hsla(0, 0%, 100%, 0.85);
        background-color: var(--doka-button-zoom--background);
      }
      .doka--button-zoom span {
        position: absolute;
        opacity: 0;
        width: 1px;
        height: 1px;
        overflow: hidden;
      }
      .doka--button-zoom:focus,
      .doka--button-zoom:hover {
        background-color: hsla(0, 0%, 100%, 0.95);
        background-color: var(--doka-button-zoom-hover--background);
      }
      @supports (
        (-webkit-backdrop-filter: var(--doka-effect--frost)) or
          (backdrop-filter: var(--doka-effect--frost))
      ) {
        .doka--button-zoom {
          background-color: hsla(0, 0%, 100%, 0.6);
          background-color: var(--doka-button-zoom-effect--background);
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: var(--doka-effect--frost);
          backdrop-filter: var(--doka-effect--frost);
        }
        .doka--button-zoom:focus,
        .doka--button-zoom:hover {
          background-color: hsla(0, 0%, 100%, 0.75);
          background-color: var(--doka-button-zoom-effect-hover--background);
        }
      }
      .doka--checkable {
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        height: 2.15625em;
        line-height: 1.5;
        color: hsla(0, 0%, 100%, 0.9);
        color: var(--doka-component--color);
        transform: scale(1);
      }
      .doka--checkable input {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: 0;
        opacity: 0;
        outline: transparent;
      }
      .doka--checkable label {
        display: inline-block;
        cursor: pointer;
        transition: opacity 0.125s ease-out;
        font-size: 0.875em;
        margin: 0;
        color: inherit;
      }
      .doka--checkable input:active + label,
      .doka--checkable input:focus + label {
        background-color: hsla(0, 0%, 100%, 0.15);
        background-color: var(--doka-component--background);
        border-radius: 0.5em;
      }
      .doka--checkable svg {
        display: block;
        margin: 0;
      }
      .doka--checkable path {
        transition: transform 0.1s ease-out;
      }
      .doka--checkable:active {
        transition-duration: 0.25s;
        transform: scale(0.925);
      }
      .doka--color {
        height: calc(100% - 3em);
        width: calc(100% - 2em);
        left: 1em;
        top: 1em;
      }
      .doka--color .doka--color-form {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: start;
        align-items: flex-start;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 1em 0 0;
        height: 6.5em;
        box-sizing: border-box;
        margin: 0;
        contain: layout size style;
      }
      .doka--color .doka--range-input {
        pointer-events: all;
        margin: 0 0.5em;
        max-width: 10em;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -ms-flex-align: stretch;
        align-items: stretch;
        -ms-flex: 1;
        flex: 1;
      }
      .doka--color .doka--range-input label {
        color: inherit;
        margin-top: 0.25em;
        font-size: 0.75em;
        -ms-flex-item-align: center;
        align-self: center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
      }
      .doka--color .doka--range-input svg {
        display: inline-block;
        width: 1.25em;
        margin-right: 0.5em;
      }
      .doka--color .doka--range-input [stroke-width] {
        stroke-width: 2.5;
      }
      @media (-ms-high-contrast: none) {
        .doka--color-form .doka--range-input,
        ::-ms-backdrop {
          -ms-flex: none;
          flex: none;
          width: 10em;
        }
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--color {
        top: 2em;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--color-form {
        padding: 0.5em 0 0;
        height: 5em;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 -0.5em;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
        width: 100%;
        max-width: 25em;
        padding: 0;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--range-input {
        width: calc(50% - 1em);
        max-width: none;
        -ms-flex: auto;
        flex: auto;
        -ms-flex-direction: row;
        flex-direction: row;
        margin: 0 0.25em;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--range-input label {
        margin: 0;
        width: 1.25em;
        overflow: hidden;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--range-input label svg {
        margin: 0;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--range-input label span {
        display: none;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--range-input:nth-child(2n) {
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
      }
      .doka--container {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
      }
      .doka--container[data-limit-overflow="true"] {
        overflow: hidden;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--container {
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
      }
      .doka--content {
        position: relative;
        -ms-flex: 1;
        flex: 1;
      }
      .doka--content > * {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      .doka--view-stack {
        z-index: 2;
      }
      .doka--image {
        z-index: 1;
      }
      .doka--instructions-bubble {
        color: #fff;
        color: var(--doka-instruction-bubble--color);
        background: radial-gradient(
          closest-side ellipse,
          rgba(0, 0, 0, 0.22) 20%,
          rgba(0, 0, 0, 0.21) 35%,
          rgba(0, 0, 0, 0.18) 50%,
          rgba(0, 0, 0, 0.1) 70%,
          rgba(0, 0, 0, 0.05) 80%,
          rgba(0, 0, 0, 0.01) 90%,
          transparent
        );
        background: var(--doka-instruction-bubble--background);
        filter: drop-shadow(0 0.125em 0.75em black);
        filter: drop-shadow(var(--doka-instruction-bubble--color-shadow));
        position: absolute;
        left: 0;
        top: 0;
        max-width: 15em;
        line-height: 1.5;
        text-align: center;
        padding: 3em 4em 4em;
        font-size: 0.75em;
        pointer-events: none;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        -ms-flex-align: center;
        align-items: center;
      }
      .doka--instructions-bubble svg:first-child {
        margin-bottom: 0.5em;
      }
      .doka--crop-mask {
        position: absolute;
        left: 0;
        top: 0;
        will-change: transform;
        pointer-events: none;
      }
      .doka--crop-rect {
        z-index: 2;
        position: relative;
      }
      .doka--crop-rect-corner {
        color: transparent;
        position: absolute;
        pointer-events: all;
        z-index: 3;
        padding: 0;
        margin: 0;
        border: none;
        text-indent: 0;
        width: 20px;
        height: 20px;
        left: -10px;
        top: -10px;
        will-change: transform;
      }
      .doka--crop-rect-corner:after {
        position: absolute;
        left: -1em;
        right: -1em;
        top: -1em;
        bottom: -1em;
        content: "";
      }
      .doka--crop-rect-corner-ne,
      .doka--crop-rect-corner-sw {
        cursor: nesw-resize !important;
      }
      .doka--crop-rect-corner-nw,
      .doka--crop-rect-corner-se {
        cursor: nwse-resize !important;
      }
      .doka--root[data-style-crop-corner="circle"] .doka--crop-rect-corner {
        background: #fff;
        background: var(--doka-crop-corner--color);
        box-shadow: 0 0.0625em 0.125em rgba(0, 0, 0, 0.2);
        box-shadow: var(--doka-crop-corner--shadow);
        border-radius: 9999em;
        transition: background-color 0.25s ease-in-out;
      }
      .doka--root[data-style-crop-corner="line"] .doka--crop-rect-corner:before {
        position: absolute;
        width: 2.5em;
        height: 2.5em;
        pointer-events: none;
        transition: opacity 0.25s ease-out;
        content: "";
      }
      .doka--root[data-style-crop-corner="line"] .doka--crop-rect-corner-nw:before {
        box-shadow: inset 0.25em 0.25em 0 0 #fff;
        box-shadow: inset 0.25em 0.25em 0 0 var(--doka-crop-corner--color);
        left: 0.4375em;
        top: 0.4375em;
      }
      .doka--root[data-style-crop-corner="line"] .doka--crop-rect-corner-ne:before {
        box-shadow: inset -0.25em 0.25em 0 0 #fff;
        box-shadow: inset -0.25em 0.25em 0 0 var(--doka-crop-corner--color);
        right: 0.4375em;
        top: 0.4375em;
      }
      .doka--root[data-style-crop-corner="line"] .doka--crop-rect-corner-se:before {
        box-shadow: inset -0.25em -0.25em 0 0 #fff;
        box-shadow: inset -0.25em -0.25em 0 0 var(--doka-crop-corner--color);
        right: 0.4375em;
        bottom: 0.4375em;
      }
      .doka--root[data-style-crop-corner="line"] .doka--crop-rect-corner-sw:before {
        box-shadow: inset 0.25em -0.25em 0 0 #fff;
        box-shadow: inset 0.25em -0.25em 0 0 var(--doka-crop-corner--color);
        left: 0.4375em;
        bottom: 0.4375em;
      }
      .doka--root[data-style-crop-corner="line"]
        .doka--crop-rect[data-indicator-size="none"]
        .doka--crop-rect-corner:before {
        opacity: 0;
      }
      .doka--crop-rect-focal-line {
        background: hsla(0, 0%, 100%, 0.5);
        background: var(--doka-crop-focal-line--color);
        height: 100px;
        width: 100px;
        z-index: 3;
        pointer-events: none;
        will-change: transform, opacity;
      }
      .doka--crop-rect-edge,
      .doka--crop-rect-focal-line {
        position: absolute;
        left: 0;
        top: 0;
        transform-origin: left top;
      }
      .doka--crop-rect-edge {
        padding: 0;
        border: 0;
        background: transparent;
        z-index: 2;
        pointer-events: all;
        will-change: transform;
      }
      .doka--crop-rect-edge:focus {
        outline: transparent;
      }
      .doka--crop-rect-edge:focus:after {
        position: absolute;
        content: "";
        background-color: hsla(0, 0%, 100%, 0.5);
        background-color: var(--doka-crop-edge-focus--color);
      }
      .doka--crop-rect-edge-n,
      .doka--crop-rect-edge-s {
        width: 100px;
        top: -20px;
        height: 40px;
        cursor: ns-resize !important;
      }
      .doka--crop-rect-edge-n:after,
      .doka--crop-rect-edge-s:after {
        left: 0;
        right: 0;
        top: 17px;
        width: 100%;
        height: 7px;
      }
      .doka--crop-rect-edge-e,
      .doka--crop-rect-edge-w {
        cursor: ew-resize !important;
        height: 100px;
        left: -20px;
        width: 40px;
      }
      .doka--crop-rect-edge-e:after,
      .doka--crop-rect-edge-w:after {
        top: 0;
        bottom: 0;
        left: 17px;
        height: 100%;
        width: 7px;
      }
      .doka--root[data-style-viewport*="touch"][data-style-crop-corner="circle"]
        .doka--crop-rect-corner:after {
        border-radius: 50%;
        opacity: 0;
        background: #fff;
        background: var(--doka-crop-corner-focus--color);
        box-shadow: 0 0 0 1em #fff;
        box-shadow: 0 0 0 1em var(--doka-crop-corner-focus--color);
      }
      .doka--root[data-style-viewport*="touch"][data-style-crop-corner="circle"]
        .doka--crop-rect-corner:active:after {
        opacity: 0.25;
      }
      .doka--root[data-style-viewport*="touch"] .doka--crop-rect-edge:active {
        outline: transparent;
      }
      .doka--root[data-style-viewport*="touch"] .doka--crop-rect-edge:active:after {
        position: absolute;
        background-color: hsla(0, 0%, 100%, 0.5);
        background-color: var(--doka-crop-edge-focus--color);
        content: "";
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--crop-rect-corner {
        width: 16px;
        height: 16px;
        left: -8px;
        top: -8px;
      }
      .doka--root[data-style-viewport*="x-cramped"][data-style-crop-corner="line"]
        .doka--crop-rect-corner-nw:before {
        left: 0.325em;
        top: 0.325em;
      }
      .doka--root[data-style-viewport*="x-cramped"][data-style-crop-corner="line"]
        .doka--crop-rect-corner-ne:before {
        right: 0.325em;
        top: 0.325em;
      }
      .doka--root[data-style-viewport*="x-cramped"][data-style-crop-corner="line"]
        .doka--crop-rect-corner-se:before {
        right: 0.325em;
        bottom: 0.325em;
      }
      .doka--root[data-style-viewport*="x-cramped"][data-style-crop-corner="line"]
        .doka--crop-rect-corner-sw:before {
        left: 0.325em;
        bottom: 0.325em;
      }
      .doka--crop-resizer {
        visibility: hidden;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 9999999;
        background: transparent;
        pointer-events: none;
      }
      .doka--crop-resizer div {
        position: absolute;
        cursor: none;
        left: 0;
        top: 0;
        width: 44px;
        height: 44px;
        margin-left: -22px;
        margin-top: -22px;
        background: hsla(0, 0%, 100%, 0.85);
        border-radius: 22px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        pointer-events: all;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: var(--doka-effect--frost);
        backdrop-filter: var(--doka-effect--frost);
        opacity: 0;
        transition: opacity 0.25s ease-out, visibility 0.25s ease-out;
      }
      .doka--crop-resizer[data-state="multi-touch"] {
        visibility: visible;
        cursor: none;
        pointer-events: all;
      }
      .doka--crop-resizer[data-state="multi-touch"] div {
        opacity: 1;
      }
      .doka--crop-rotator {
        position: relative;
        height: 3.5em;
        margin-top: -0.5em;
        margin-bottom: 0.5em;
        z-index: 2;
        pointer-events: all;
      }
      .doka--crop-rotator-center {
        left: 50%;
        position: absolute;
        overflow: hidden;
        width: 2em;
        top: 0.325em;
        height: 1.5em;
        font-size: 0.75em;
        cursor: pointer;
        border: none;
        padding: 0;
        margin: 0 0 0 -1em;
        background: transparent;
        opacity: 0.3;
        z-index: 2;
        color: inherit;
      }
      .doka--crop-rotator-center span {
        color: transparent;
      }
      .doka--crop-rotator-center:after {
        position: absolute;
        left: 50%;
        height: 0.85em;
        border-radius: 9999em;
        width: 1.5px;
        color: inherit;
        background: currentColor;
        transition: background-color 0.25s ease-in-out;
        margin-left: -0.75px;
        top: 50%;
        margin-top: -0.55em;
        pointer-events: none;
        content: "";
      }
      .doka--crop-rotator-center:focus {
        outline: transparent;
        opacity: 0.7;
      }
      .doka--crop-rotator-bar {
        position: absolute;
        left: 1em;
        right: 1em;
        bottom: 0;
        top: 0;
        z-index: 1;
        cursor: ew-resize;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .doka--crop-rotator-line-mask {
        height: 100%;
        overflow: hidden;
        max-width: 30em;
        margin: 0 auto;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--crop-rotator {
        margin-top: -0.5em;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--crop-rotator-line-mask {
        max-width: 14em;
      }
      @supports ((-webkit-mask-image: url("")) or (mask-image: url(""))) and
        (mix-blend-mode: screen) {
        .doka--crop-rotator-line-mask,
        .doka--root[data-style-viewport*="x-cramped"] .doka--crop-rotator-line-mask {
          max-width: 40em;
          margin: 0 auto;
          -webkit-mask: linear-gradient(
            90deg,
            transparent 15%,
            #000 30%,
            #000 70%,
            transparent 85%
          );
          mask: linear-gradient(
            90deg,
            transparent 15%,
            #000 30%,
            #000 70%,
            transparent 85%
          );
        }
      }
      .doka--crop-rotator-line {
        pointer-events: none;
        height: 100%;
      }
      .doka--crop-rotator-line svg {
        position: absolute;
        left: 50%;
        margin-left: -500px;
        width: 1000px;
      }
      .doka--crop-rotator-line text {
        font-weight: 400;
        font-size: 2px;
        opacity: 0.35;
      }
      .doka--crop-rotator-line circle {
        opacity: 0.4;
      }
      .doka--crop-rotator-line circle[r="0.5"] {
        opacity: 0.9;
      }
      .doka--crop-rotator:focus {
        outline: transparent;
      }
      .doka--crop-rotator:focus .doka--crop-rotator-line circle,
      .doka--crop-rotator:focus .doka--crop-rotator-line text {
        fill: currentColor;
      }
      .doka--crop-size {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
        overflow: hidden;
        color: rgba(0, 0, 0, 0.85);
        color: var(--doka-size-indicator--color);
        background: hsla(0, 0%, 100%, 0.95);
        background: var(--doka-size-indicator--background);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: var(--doka-effect--frost);
        backdrop-filter: var(--doka-effect--frost);
        border-radius: 0.25em;
        border-radius: var(--doka-size-indicator--radius);
        line-height: 1;
        white-space: nowrap;
        transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
        transform-origin: center center;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        pointer-events: none;
        font-variant-numeric: tabular-nums;
        -webkit-font-feature-settings: "tnum";
        font-feature-settings: "tnum";
        font-family: Tahoma, Geneva, Verdana, sans-serif;
      }
      .doka--crop-size-info {
        font-size: 0.6875em;
        padding: 0.35em 0.625em;
      }
      .doka--crop-resize-percentage {
        padding-right: 0.4375em;
        margin-right: -0.25em;
        border-right: 1px solid rgba(0, 0, 0, 0.15);
        border-right: 1px solid var(--doka-size-indicator--edge);
      }
      .doka--crop-resize-percentage:empty {
        display: none;
      }
      .doka--crop-size-multiply {
        opacity: 0.75;
        margin: 0 0.175em;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--crop-size-info {
        font-size: 0.5em;
        padding: 0.5em 0.875em;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--crop-resize-percentage {
        padding-right: 0.4em;
      }
      .doka--crop-subject {
        position: relative;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        margin: 0.25em 1.5em 1.5em;
        contain: layout size style;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--crop-subject {
        margin: 0 2em 1em;
      }
      .doka--zoom-wrapper {
        position: relative;
        z-index: 2;
        pointer-events: all;
      }
      .doka--button-zoom {
        position: absolute;
        z-index: 2;
        left: -1.25em;
        top: -1.25em;
      }
      .doka--dropdown,
      .doka--view-stack .doka--crop {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
      }
      .doka--dropdown {
        position: relative;
        -ms-flex-pack: center;
        justify-content: center;
      }
      .doka--dropdown > .doka--button {
        height: 100%;
      }
      .doka--dropdown-list {
        position: absolute;
        z-index: 3;
        padding: 0;
        top: 100%;
        margin: -0.125em 0 0;
        min-width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        border-radius: 0.1625em;
        background-color: hsla(0, 0%, 100%, 0.85);
        background-color: var(--doka-component-alt--background);
        box-shadow: 0 0.75em 1em -0.75em rgba(0, 0, 0, 0.4),
          0 1em 2em 0 rgba(0, 0, 0, 0.05);
        box-shadow: var(--doka-component-alt--shadow);
      }
      .doka--dropdown-list .doka--button {
        position: static;
        width: auto;
        height: auto;
        margin: 0;
        padding: 0;
        font-family: inherit;
        font-size: 1em;
        outline: transparent;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        white-space: nowrap;
        border: none;
        line-height: 2.5;
        margin: 3px;
        padding: 0 0.75em 0 0.5em;
        border-radius: 0.125em;
        color: rgba(0, 0, 0, 0.85);
        color: var(--doka-component-alt--color);
        background-color: transparent;
        transform: none;
      }
      .doka--dropdown-list .doka--button span {
        font-size: 0.875em;
        text-align: left;
        -ms-flex: 1;
        flex: 1;
      }
      .doka--dropdown-list .doka--button svg + span {
        margin-left: 0.5em;
      }
      .doka--dropdown-list .doka--button svg {
        display: inline-block;
        margin: 0;
      }
      .doka--dropdown-list .doka--button:active,
      .doka--dropdown-list .doka--button:focus,
      .doka--dropdown-list .doka--button:hover {
        background-color: rgba(0, 0, 0, 0.1);
        background-color: var(--doka-dropdown-hover--background);
      }
      .doka--dropdown-list .doka--button:active:hover,
      .doka--dropdown-list .doka--button:focus:hover {
        background-color: rgba(0, 0, 0, 0.15);
        background-color: var(--doka-dropdown-hover-active--background);
      }
      .doka--dropdown-list .doka--button:active {
        transform: none;
      }
      .doka--dropdown-list .doka--button:first-child,
      .doka--dropdown-list .doka--button:last-child {
        border-radius: inherit;
      }
      .doka--dropdown-list .doka--button:first-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
      .doka--dropdown-list .doka--button:last-child {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      }
      .doka--dropdown-list .doka--button + .doka--button {
        margin-top: -2px;
      }
      .doka--dropdown-list [aria-selected="true"] {
        color: hsla(0, 0%, 100%, 0.9);
        color: var(--doka-dropdown-selected--color);
        background-color: rgba(0, 0, 0, 0.9);
        background-color: var(--doka-dropdown-selected--background);
      }
      .doka--dropdown-list [aria-selected="true"]:active,
      .doka--dropdown-list [aria-selected="true"]:focus,
      .doka--dropdown-list [aria-selected="true"]:hover {
        background-color: rgba(0, 0, 0, 0.8);
        background-color: var(--doka-dropdown-selected-hover--background);
      }
      .doka--dropdown-list [aria-selected="true"]:active:hover,
      .doka--dropdown-list [aria-selected="true"]:focus:hover {
        background-color: rgba(0, 0, 0, 0.6);
        background-color: var(--doka-dropdown-selected-active-hover--background);
      }
      @supports (
        (-webkit-backdrop-filter: var(--doka-effect--frost)) or
          (backdrop-filter: var(--doka-effect--frost))
      ) {
        .doka--dropdown-list {
          background-color: hsla(0, 0%, 100%, 0.6);
          background-color: var(--doka-component-alt-effect--background);
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: var(--doka-effect--frost);
          backdrop-filter: var(--doka-effect--frost);
        }
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--dropdown {
        position: static;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--dropdown-list {
        position: absolute;
        min-width: auto;
        top: 3em;
        left: 3em;
        right: 3em;
        max-width: 15em;
        margin: 0 auto;
      }
      @media (-ms-high-contrast: none) {
        .doka--root .doka--dropdown-list,
        ::-ms-backdrop {
          min-width: 10em;
        }
      }
      .doka--edit-status {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        contain: strict;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.25s ease-out;
        background: hsla(0, 0%, 100%, 0.1);
        background: var(--doka-status--background);
      }
      .doka--edit-status .doka--status-bubble {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
      }
      .doka--edit-status[data-view-status="busy"] {
        pointer-events: all;
        opacity: 1;
      }
      .doka--edit-status:empty {
        display: none;
      }
      .doka--editor {
        height: 100%;
        width: 100%;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        max-width: 80em;
        max-width: var(--doka-editor--max-width);
        max-height: 60em;
        max-height: var(--doka-editor--max-height);
        opacity: 0;
      }
      .doka--filter-list {
        display: -ms-inline-flexbox;
        display: inline-flex;
        -ms-flex-align: start;
        align-items: flex-start;
        list-style: none;
        margin: 0;
        padding: 0;
      }
      .doka--filter-list li {
        margin: 0.25em 0.5em;
        padding: 0;
      }
      .doka--filter-list li:first-child {
        margin-left: 0;
      }
      .doka--filter-list li:last-child {
        margin-right: 0;
      }
      @media (-ms-high-contrast: none) {
        .doka--filter-list li,
        ::-ms-backdrop {
          -ms-flex: none;
          flex: none;
          width: 4em;
        }
      }
      .doka--filter-tile {
        position: relative;
        border-radius: 0;
        border-radius: var(--doka-filter-tile--radius);
      }
      .doka--filter-tile:before {
        display: block;
        padding-top: 100%;
        content: "";
        height: 0;
      }
      .doka--filter-tile[style*="transform:translate3d(0px, 0px, 0)"] {
        transform: none !important;
      }
      .doka--filter-tile div,
      .doka--filter-tile label {
        transition: box-shadow 0.125s ease-out, color 0.125s ease-out;
      }
      .doka--filter-tile label {
        position: absolute;
        color: hsla(0, 0%, 100%, 0.9);
        color: var(--doka-component--color);
        text-align: center;
        font-size: 0.6875em;
        top: calc(100% + 0.5em);
        width: 100%;
        left: 0;
      }
      .doka--filter-tile > div {
        margin-bottom: 0.5em;
        z-index: 2;
        border-radius: 0;
        border-radius: var(--doka-filter-tile--radius);
        overflow: hidden;
        box-shadow: 0 0 0.75em rgba(0, 0, 0, 0.1);
        pointer-events: none;
      }
      .doka--filter-tile > div canvas {
        z-index: 1;
      }
      .doka--filter-tile > div div {
        z-index: 2;
      }
      .doka--filter-tile canvas,
      .doka--filter-tile div,
      .doka--filter-tile input {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        min-width: auto;
        min-height: auto;
        font-size: 1em;
      }
      .doka--filter-tile input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        position: absolute;
        left: 0;
        top: 0;
        margin: 0;
        padding: 0;
        border-radius: 0;
        opacity: 0;
        outline: transparent;
        pointer-events: all;
      }
      .doka--filter-tile input:checked ~ label {
        color: #ffd843;
        color: var(--doka-filter-tile-selected--color);
      }
      .doka--filter-tile input:checked ~ div {
        box-shadow: 0 0.5px 0 1px rgba(0, 0, 0, 0.2), 0 0 0 4px #ffd843;
        box-shadow: 0 0.5px 0 1px rgba(0, 0, 0, 0.2),
          0 0 0 4px var(--doka-filter-tile-selected--color);
      }
      .doka--filter-tile input:focus ~ label {
        color: #ffd843;
        color: var(--doka-filter-tile-selected--color);
      }
      .doka--filter {
        height: calc(100% - 3em);
        width: calc(100% - 2em);
        left: 1em;
        top: 1em;
      }
      .doka--filter .doka--filter-list {
        padding-bottom: 1.5em;
      }
      .doka--filter .doka--filter-scroller {
        margin-bottom: 1em;
      }
      .doka--filter-scroller {
        -webkit-overflow-scrolling: touch;
        overscroll-behavior-x: none;
        -ms-touch-action: pan-x;
        touch-action: pan-x;
        text-align: center;
        pointer-events: all;
        width: 40em;
        margin: 0 auto;
        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        scrollbar-width: none;
        scrollbar-color: hsla(0, 0%, 100%, 0.2) transparent;
        scrollbar-color: var(--doka-scroller-thumb--color) transparent;
      }
      @supports (-ms-ime-align: auto) {
        .doka--filter-scroller {
          overflow-x: hidden;
        }
      }
      .doka--filter-scroller[data-drag-state="dragging"] input {
        pointer-events: none !important;
      }
      .doka--filter-scroller::-webkit-scrollbar {
        height: 0.375em;
        background-color: transparent;
        background-color: var(--doka-scroller-rail--color);
      }
      .doka--filter-scroller::-webkit-scrollbar-track {
        background: transparent;
      }
      .doka--filter-scroller::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 9999rem;
        border: 3px solid transparent;
        border: 3px solid var(--doka-scroller-rail--color);
        transition: background-color 0.25s ease-in-out;
      }
      @media (pointer: fine) and (hover: hover) {
        .doka--filter-scroller:hover {
          scrollbar-width: thin;
        }
        .doka--filter-scroller:hover::-webkit-scrollbar-thumb {
          background-color: hsla(0, 0%, 100%, 0.2);
          background-color: var(--doka-scroller-thumb--color);
        }
      }
      .doka--filter-list {
        padding: 0 2em;
      }
      .doka--filter-tile {
        -ms-flex: none;
        flex: none;
        width: 4em;
        scroll-snap-align: start;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--filter-tile {
        width: 2.875em;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--filter {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        top: 2em;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--filter .doka--filter-list {
        padding: 0 1em;
        height: 5em;
      }
      .doka--root[data-style-viewport*="x-cramped"] .doka--filter-scroller {
        -ms-scroll-snap-type: proximity;
        scroll-snap-type: proximity;
        -ms-scroll-snap-type: x proximity;
        scroll-snap-type: x proximity;
        scroll-padding: 2em;
        margin-bottom: 0;
      }
      .doka--filter-scroller {
        scrollbar-base-color: #111;
        scrollbar-face-color: #333;
        scrollbar-3dlight-color: #111;
        scrollbar-highlight-color: #111;
        scrollbar-track-color: #111;
        scrollbar-arrow-color: #111;
        scrollbar-shadow-color: #111;
      }
      @supports ((-webkit-mask-image: url("")) or (mask-image: url(""))) and
        (mix-blend-mode: screen) {
        .doka--filter-scroller {
          margin: 0 auto;
          -webkit-mask: linear-gradient(
              90deg,
              transparent,
              #000 2em,
              #000 calc(100% - 2em),
              transparent
            ),
            linear-gradient(0deg, #000 8px, transparent 0);
          mask: linear-gradient(
              90deg,
              transparent,
              #000 2em,
              #000 calc(100% - 2em),
              transparent
            ),
            linear-gradient(0deg, #000 8px, transparent 0);
        }
        .doka--root[data-style-viewport*="x-cramped"] .doka--filter-scroller {
          -webkit-mask: linear-gradient(
            90deg,
            transparent,
            #000 1em,
            #000 calc(100% - 1em),
            transparent
          );
          mask: linear-gradient(
            90deg,
            transparent,
            #000 1em,
            #000 calc(100% - 1em),
            transparent
          );
        }
      }
      .doka--image-gl {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .doka--image-gl canvas {
        position: absolute;
        left: 0;
        top: 0;
        color: #fff;
        color: var(--doka-editor-outline--color);
        background: rgba(0, 0, 0, 0.99);
        background: var(--doka-editor--background-color);
      }
      .doka--image-gl[tabindex="0"] canvas {
        pointer-events: all;
        cursor: move;
      }
      .doka--image-gl:after {
        content: "";
        transition: opacity 0.15s ease-in-out;
        opacity: 0;
        position: absolute;
        left: calc(50% - 1.3em);
        top: calc(50% - 1.3em);
        width: 2.75em;
        height: 2.75em;
        pointer-events: none;
        border-radius: 9999em;
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: 2em;
        background-color: rgba(0, 0, 0, 0.5);
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='rgba(255,255,255,.9)' stroke-width='1.5'%3E%3Crect x='8.5' y='3.5' width='7' height='7' rx='2'/%3E%3Crect x='1.5' y='10.5' width='7' height='7' rx='2'/%3E%3Crect x='8.5' y='10.5' width='7' height='7' rx='2'/%3E%3Crect x='15.5' y='10.5' width='7' height='7' rx='2'/%3E%3C/g%3E%3C/svg%3E");
      }
      .doka--image-gl[data-show-interaction-indicator="true"]:focus:after {
        opacity: 1;
      }
      .doka--button-destroy-shape {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        margin: 0;
        border: none;
        font-family: inherit;
        outline: transparent;
        width: auto;
        height: auto;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        line-height: 2.5;
        font-weight: 500;
        transition: opacity 0.15s ease-out, background-color 0.25s ease-out,
          color 0.25s ease-out;
        border-radius: 9999em;
        padding: 0 1em;
        font-size: 0.75em;
        opacity: 0;
        color: rgba(0, 0, 0, 0.85);
        color: var(--doka-button-zoom--color);
        background-color: hsla(0, 0%, 100%, 0.85);
        background-color: var(--doka-button-zoom--background);
      }
      .doka--button-destroy-shape span {
        pointer-events: none;
      }
      .doka--button-destroy-shape:focus,
      .doka--button-destroy-shape:hover {
        background-color: hsla(0, 0%, 100%, 0.95);
        background-color: var(--doka-button-zoom-hover--background);
      }
      .doka--button-destroy-shape[data-active="true"] {
        opacity: 1 !important;
      }
      .doka--button-destroy-shape[data-active="false"] {
        opacity: 0 !important;
        pointer-events: none;
      }
      @supports (
        (-webkit-backdrop-filter: var(--doka-effect--frost)) or
          (backdrop-filter: var(--doka-effect--frost))
      ) {
        .doka--button-destroy-shape {
          background-color: hsla(0, 0%, 100%, 0.6);
          background-color: var(--doka-button-zoom-effect--background);
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: var(--doka-effect--frost);
          backdrop-filter: var(--doka-effect--frost);
        }
        .doka--button-destroy-shape:focus,
        .doka--button-destroy-shape:hover {
          background-color: hsla(0, 0%, 100%, 0.75);
          background-color: var(--doka-button-zoom-effect-hover--background);
        }
      }
      .doka--image-markup {
        position: absolute;
        left: 0;
        top: 0;
        pointer-events: all;
      }
      .doka--image-markup svg {
        contain: strict;
      }
      .doka--image-markup[data-util="draw"] svg {
        cursor: crosshair;
      }
      .doka--image-markup[data-util="draw"] .doka--shape-group {
        pointer-events: none;
      }
      .doka--image-markup text {
        white-space: pre;
      }
      .doka--image-markup image {
        transition: opacity 0.25s ease-in-out;
      }
      .doka--image-markup input {
        background: transparent;
        position: absolute;
        left: 0;
        top: 0;
        line-height: 1 !important;
        text-align: left !important;
        text-indent: 0 !important;
        border: 0 !important;
        margin: 0 !important;
        padding: 0 !important;
        box-shadow: none !important;
        pointer-events: none;
        outline: none;
        color: #fff;
        z-index: 1;
      }
      .doka--image-markup input::-ms-clear {
        display: none;
      }
      .doka--image-markup input:focus {
        pointer-events: all;
      }
      .doka--image-markup[data-active="true"] {
        pointer-events: all !important;
      }
      .doka--manipulator-group {
        pointer-events: none;
      }
      .doka--manipulator-group circle {
        pointer-events: all;
        fill: #2990ff;
        fill: var(--doka-markup-manipulator--fill);
        stroke: #fff;
        stroke: var(--doka-markup-manipulator--stroke);
        stroke-width: 2;
      }
      .doka--manipulator-group path,
      .doka--manipulator-group rect {
        stroke-width: 1.5;
        stroke: hsla(0, 0%, 100%, 0.75);
        stroke: var(--doka-markup-manipulator--line);
      }
      .doka--manipulator-group[is-bright-color="true"] path {
        stroke: rgba(0, 0, 0, 0.75);
        stroke: var(--doka-markup-manipulator--line-inverted);
      }
      .doka--image {
        pointer-events: none;
      }
      .doka--root[data-style-layout-mode~="fullscreen"] {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 2147483647;
        opacity: 0;
      }
      .doka--root[data-style-layout-mode~="fullscreen"]:focus {
        outline: transparent;
      }
      .doka--root[data-style-layout-mode~="fullscreen"] .doka--edit-status {
        position: fixed;
      }
      .doka--root[data-style-layout-mode~="fullscreen"][data-style-fullscreen-safe-area="bottom"][data-style-fullscreen="true"] {
        padding-bottom: 40px;
        box-sizing: border-box;
      }
      @media (min-height: 635px) {
        .doka--root[data-style-layout-mode~="fullscreen"][data-style-fullscreen-safe-area="bottom"][data-style-fullscreen="true"] {
          padding-bottom: 80px;
        }
      }
      .doka--parent::-webkit-scrollbar {
        background-color: rgba(0, 0, 0, 0.85);
        background-color: var(--doka-scrollbar-rail--color);
      }
      .doka--parent::-webkit-scrollbar-track {
        background: transparent;
      }
      .doka--parent::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.75);
        background: var(--doka-scrollbar-thumb--color);
        border-radius: 9999rem;
        border: 3px solid rgba(0, 0, 0, 0.85);
        border: 3px solid var(--doka-scrollbar-rail--color);
      }
      @media (min-width: 56rem) and (min-height: 40rem) {
        .doka--root[data-style-layout-mode~="modal"] {
          background: rgba(0, 0, 0, 0.8);
          background: var(--doka-modal--overlay);
          transition: background-color 0.25s ease-in-out;
        }
        .doka--root[data-style-layout-mode~="modal"]:before {
          content: none;
        }
        .doka--root[data-style-layout-mode~="modal"] .doka--editor {
          position: relative;
          overflow: hidden;
          background: #000;
          background: var(--doka-modal--background);
          box-shadow: 0 1em 4em rgba(0, 0, 0, 0.25), 0 0.5em 2em rgba(0, 0, 0, 0.25);
          box-shadow: var(--doka-modal--shadow);
          transition: background-color 0.25s ease-in-out;
          width: 70vw;
          width: var(--doka-modal--width);
          height: 80vh;
          height: var(--doka-modal--height);
          max-width: 56rem;
          max-width: var(--doka-modal--max-width);
          max-height: 40rem;
          max-height: var(--doka-modal--max-height);
        }
        .doka--root[data-style-layout-mode~="modal"] .doka--edit-status {
          position: absolute;
        }
      }
      .doka--root[data-style-layout-mode~="preview"] {
        background: transparent;
      }
      .doka--root[data-style-layout-mode~="preview"]:before {
        content: none;
      }
      .doka--root[data-style-layout-mode~="preview"] .doka--content {
        position: relative;
        z-index: 1;
      }
      .doka--root[data-style-layout-mode~="preview"] .doka--editor {
        max-width: none;
        max-height: none;
      }
      .doka--root[data-style-layout-mode~="preview"] .doka--image-outline,
      .doka--root[data-style-layout-mode~="preview"] .doka--utils {
        display: none;
      }
      .doka--root[data-style-layout-mode~="preview"] .doka--menu {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
        pointer-events: none !important;
      }
      .doka--root[data-style-layout-mode~="preview"] .doka--menu .doka--button {
        pointer-events: all;
      }
      .doka--root[data-style-layout-mode~="preview"] .doka--button,
      .doka--root[data-style-layout-mode~="preview"] .doka--crop-rotator {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: var(--doka-effect--frost);
        backdrop-filter: var(--doka-effect--frost);
        box-shadow: 0 0.125em 0.5em rgba(0, 0, 0, 0.325);
      }
      .doka--root[data-style-layout-mode~="preview"]
        .doka--button-app:not(.doka--button-action-confirm) {
        background-color: rgba(0, 0, 0, 0.8);
        background-color: var(--doka-component--background-dark);
      }
      .doka--root[data-style-layout-mode~="preview"]
        .doka--button-app:not(.doka--button-action-confirm):focus,
      .doka--root[data-style-layout-mode~="preview"]
        .doka--button-app:not(.doka--button-action-confirm):hover {
        background-color: rgba(0, 0, 0, 0.9);
        background-color: var(--doka-component--background-darker);
      }
      .doka--root[data-style-layout-mode~="preview"] .doka--processing-indicator p {
        color: hsla(0, 0%, 100%, 0.9);
        color: var(--doka-editor--color);
        background-color: rgba(0, 0, 0, 0.8);
        background-color: var(--doka-component--background-dark);
      }
      .doka--root[data-style-layout-mode~="preview"][data-style-viewport*="x-cramped"]
        .doka--processing-indicator {
        bottom: 0;
      }
      .doka--root[data-style-layout-mode~="preview"] .doka--toolbar {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 !important;
        z-index: 3;
      }
      .doka--root[data-style-layout-mode~="preview"]
        .doka--toolbar
        .doka--button-tool {
        background-color: rgba(0, 0, 0, 0.8);
        background-color: var(--doka-component--background-dark);
        border-radius: 0.25em;
      }
      .doka--root[data-style-layout-mode~="preview"]
        .doka--toolbar
        .doka--button-tool:focus,
      .doka--root[data-style-layout-mode~="preview"]
        .doka--toolbar
        .doka--button-tool:hover {
        background-color: rgba(0, 0, 0, 0.9);
        background-color: var(--doka-component--background-darker);
      }
      .doka--root[data-style-layout-mode~="preview"] .doka--crop-rect {
        mix-blend-mode: difference;
        height: 100%;
        pointer-events: none;
        -webkit-mask: radial-gradient(ellipse closest-side, #000, transparent);
        mask: radial-gradient(ellipse closest-side, #000, transparent);
      }
      .doka--root[data-style-layout-mode~="preview"] .doka--crop-rotator {
        position: absolute;
        bottom: 0;
        left: 12rem;
        right: 12rem;
        border-radius: 9999rem;
        background-color: rgba(0, 0, 0, 0.8);
        background-color: var(--doka-component--background-dark);
      }
      .doka--root[data-style-layout-mode~="preview"] .doka--crop-size {
        transform: none !important;
        right: 1em;
        top: 1em;
        left: auto;
      }
      .doka--root[data-style-layout-mode~="preview"] .doka--crop-rotator-line-mask {
        max-width: 100%;
      }
      .doka--root[data-style-layout-mode~="preview"] .doka--crop-subject {
        margin: 0;
      }
      .doka--root[data-style-layout-mode~="preview"]
        .doka--image-container[style*="opacity:1"]
        ~ .doka--image-overlay {
        background-color: rgba(0, 0, 0, 0.9);
        background-color: var(--doka-component--background-darker);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
      }
      .doka--root[data-style-layout-mode~="preview"][data-style-viewport~="x-cramped"]
        .doka--crop-rotator {
        display: none;
      }
      .doka--root[data-style-layout-mode~="preview"][data-style-viewport~="x-spacious"]
        .doka--crop-rotator {
        left: 16rem;
        right: 16rem;
      }
      .doka--root[data-style-layout-mode~="preview"] .doka--crop-rect-corner,
      .doka--root[data-style-layout-mode~="preview"] .doka--crop-rect-edge {
        display: none;
      }
      .doka--menu {
        display: -ms-flexbox;
        display: flex;
        box-sizing: border-box;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: center;
        align-items: center;
        z-index: 2;
        position: relative;
        pointer-events: all !important;
        transition: background-color 0.125s ease-in-out, color 0.125s ease-in-out;
        overflow: hidden;
      }
      .doka--menu .doka--button-action-confirm {
        margin-left: auto;
      }
      .doka--root:not([data-style-viewport~="x-cramped"]) .doka--menu {
        height: 4.75em;
        padding: 0 1.25em;
      }
      .doka--root:not([data-style-viewport~="x-cramped"]) .doka--utils {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 5em;
        right: 5em;
      }
      .doka--root:not([data-style-viewport~="x-cramped"])
        .doka--button-action-cancel {
        margin-right: 1em;
      }
      .doka--root:not([data-style-viewport~="x-cramped"]) .doka--button-action-reset {
        margin-right: auto;
      }
      .doka--root[data-style-viewport~="flow-controls"]:not([data-style-viewport~="x-cramped"])
        .doka--utils {
        left: 12em;
        left: var(--doka-menu--offset);
        right: 12em;
        right: var(--doka-menu--offset);
      }
      .doka--root[data-style-viewport~="x-cramped"] .doka--menu {
        margin-top: -0.75em;
        height: 3.25em;
      }
      .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
        .doka--utils {
        position: absolute;
        left: 3.25em;
        right: 3.25em;
        top: 0;
        bottom: 0;
      }
      .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
        .doka--menu {
        overflow: hidden;
        margin: 0 1em 1em;
        padding: 0 0.6125em;
        border-radius: 9999em;
        background: hsla(0, 0%, 100%, 0.1);
        background: var(--doka-component--background-dim);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: var(--doka-effect--frost);
        backdrop-filter: var(--doka-effect--frost);
      }
      .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
        .doka--button-action-reset {
        position: absolute;
        left: 0.75em;
        top: 0.6875em;
        width: 2.5em;
        height: 2.5em;
      }
      .doka--root[data-view="resize"] .doka--menu {
        background: rgba(0, 0, 0, 0.8) !important;
        background: var(--doka-component--background-dark) !important;
      }
      .doka--root[data-style-viewport~="single-util"][data-style-viewport~="no-flow-controls"]
        .doka--button-action-reset {
        position: absolute;
        top: 0.3125em;
        left: calc(50% - 1.125em);
      }
      .doka--range-input {
        display: block;
      }
      .doka--range-input button {
        position: absolute;
        top: calc(50% + 0.5em);
        height: 1.325em;
        width: 2em;
        bottom: 100%;
        left: calc(50% - 1em);
        padding: 0;
        margin: 0;
        border: none;
        font-size: 1em;
        overflow: hidden;
        color: transparent;
        background: transparent;
        outline: transparent;
      }
      .doka--range-input button:after {
        content: "";
        pointer-events: none;
        position: absolute;
        top: calc(50% - 0.5em);
        left: calc(50% - 0.06125em);
        width: 0.125em;
        height: 0.5em;
        background: hsla(0, 0%, 100%, 0.2);
        background: var(--doka-range-input-recenter--background);
        border-radius: 0.9999em;
        bottom: 0;
      }
      .doka--range-input[data-centered="true"] button:after {
        background: hsla(0, 0%, 100%, 0.2);
        background: var(--doka-range-input-centered--background);
      }
      .doka--range-input input {
        -webkit-appearance: none;
        display: block;
        font-size: 1em;
        width: 100%;
        min-width: auto;
        min-height: auto;
        position: absolute;
        left: 0.5em;
        width: calc(100% - 1em);
        margin: 0;
        padding: 0;
        line-height: 0;
        top: 0;
        height: 100%;
        background: transparent;
        border: none;
        border-radius: 0;
      }
      .doka--range-input input::-webkit-slider-runnable-track {
        background: transparent;
      }
      .doka--range-input input::-webkit-slider-thumb {
        -webkit-appearance: none;
        line-height: 0;
        margin: 0;
        padding: 0;
        border: none;
        width: 0.625em;
        height: 0.625em;
        border-radius: 0.9999em;
        transition: box-shadow 0.25s ease-in-out, background-color 0.25s ease-in-out;
        background: #fff;
        background: var(--doka-range-input-thumb--color);
      }
      .doka--range-input input::-moz-range-thumb {
        line-height: 0;
        margin: 0;
        padding: 0;
        border: none;
        width: 0.625em;
        height: 0.625em;
        border-radius: 0.9999em;
        transition: box-shadow 0.25s ease-in-out, background-color 0.25s ease-in-out;
        background: #fff;
        background: var(--doka-range-input-thumb--color);
      }
      .doka--range-input input::-ms-thumb {
        line-height: 0;
        margin: 0;
        padding: 0;
        border: none;
        width: 0.625em;
        height: 0.625em;
        border-radius: 0.9999em;
        transition: box-shadow 0.25s ease-in-out, background-color 0.25s ease-in-out;
        background: #fff;
      }
      .doka--range-input input::-ms-ticks-before,
      .doka--range-input input::-ms-tooltip {
        display: none;
      }
      .doka--range-input input::-ms-ticks-after {
        display: none;
      }
      .doka--range-input input::-ms-track {
        color: transparent;
        border: none;
        background: transparent;
      }
      .doka--range-input input::-ms-fill-lower,
      .doka--range-input input::-ms-fill-upper {
        background: transparent;
      }
      .doka--range-input input:focus {
        outline: transparent;
      }
      .doka--range-input input:focus::-moz-range-thumb {
        box-shadow: 0 0 0 0.5em hsla(0, 0%, 100%, 0.15);
        box-shadow: 0 0 0 0.5em var(--doka-range-input-thumb-hover--color);
      }
      .doka--range-input input:focus::-webkit-slider-thumb {
        box-shadow: 0 0 0 0.5em hsla(0, 0%, 100%, 0.15);
        box-shadow: 0 0 0 0.5em var(--doka-range-input-thumb-hover--color);
      }
      .doka--range-input span:hover input::-moz-range-thumb {
        box-shadow: 0 0 0 0.5em hsla(0, 0%, 100%, 0.15);
        box-shadow: 0 0 0 0.5em var(--doka-range-input-thumb-hover--color);
      }
      .doka--range-input span:hover input::-webkit-slider-thumb {
        box-shadow: 0 0 0 0.5em hsla(0, 0%, 100%, 0.15);
        box-shadow: 0 0 0 0.5em var(--doka-range-input-thumb-hover--color);
      }
      .doka--range-input-inner {
        display: block;
        position: relative;
        -ms-flex: 1;
        flex: 1;
        min-height: 2em;
      }
      .doka--range-input-inner:after {
        content: "";
        position: absolute;
        left: 0.5em;
        right: 0.5em;
        top: calc(50% - 0.125em);
        height: 0.25em;
        background-color: hsla(0, 0%, 100%, 0.2);
        background-color: var(--doka-range-input--background);
        transition: background-color 0.25s ease-in-out;
        border-radius: 0.9999em;
      }
      .doka--resize-form {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        pointer-events: all;
        padding: 1em 0;
        margin: 0;
        height: 2.5em;
      }
      .doka--resize-form fieldset,
      .doka--resize-form legend {
        border: none;
        padding: 0;
        margin: 0;
      }
      .doka--resize-form legend {
        position: absolute;
        width: 1px;
        height: 1px;
        opacity: 0;
      }
      .doka--resize-form fieldset {
        margin: 0;
        padding: 0;
        -ms-flex: 1 0 auto;
        flex: 1 0 auto;
        max-width: 17em;
      }
      .doka--resize-form .doka--size-input {
        margin: 0;
        width: calc(50% - 1.125em);
      }
      .doka--resize-form .doka--checkable {
        margin: 0 0.325em;
        vertical-align: bottom;
      }
      .doka--resize-form .doka--checkable label {
        padding: 0.3125em 0;
      }
      .doka--resize-form button {
        margin-right: -3.25em;
        margin-left: 1em;
        font-size: 0.875em;
      }
      .doka--resize-form button svg {
        width: 1.25em;
        height: 1.25em;
      }
      .doka--root[data-view="resize"] .doka--resize-form {
        background-color: rgba(0, 0, 0, 0.8);
        background-color: var(--doka-component--background-dark);
      }
      .doka--root[data-style-viewport~="flow-controls"][data-style-viewport~="single-util"]:not([data-style-viewport~="x-cramped"])
        .doka--resize-form {
        margin-top: -4.5em;
        background: none;
      }
      .doka--root[data-style-viewport*="touch"] .doka--resize-form button {
        position: absolute;
        opacity: 0;
        width: 1px;
        height: 1px;
        overflow: hidden;
      }
      .doka--view-stack .doka--resize {
        height: calc(100% - 1em) !important;
      }
      .doka--size-input {
        position: relative;
        display: -ms-inline-flexbox;
        display: inline-flex;
        cursor: text;
      }
      .doka--size-input input::-webkit-inner-spin-button,
      .doka--size-input input::-webkit-outer-spin-button {
        -webkit-appearance: none;
      }
      .doka--size-input input::-ms-clear {
        display: none;
      }
      .doka--size-input input,
      .doka--size-input label {
        padding: 0.625em 0.5em;
        text-indent: 0.25em;
        font-size: 0.875em;
        font-weight: 400;
        line-height: inherit;
        cursor: inherit;
      }
      .doka--size-input label {
        pointer-events: none;
        position: absolute;
        right: 0;
        cursor: text;
        -ms-flex: 1;
        flex: 1;
        white-space: nowrap;
        text-align: right;
        padding-left: 0;
        padding-right: 0.75em;
      }
      .doka--size-input input,
      .doka--size-input label {
        color: hsla(0, 0%, 100%, 0.9);
        color: var(--doka-editor--color);
      }
      .doka--size-input input {
        -webkit-appearance: none;
        appearance: none;
        -moz-appearance: textfield;
        margin: 0;
        min-height: 0;
        min-width: 7em;
        height: auto;
        width: 100%;
        font-variant-numeric: tabular-nums;
        outline: transparent;
        border-radius: 0.5em;
        box-shadow: none;
        border: none;
        background-color: hsla(0, 0%, 100%, 0.15);
        background-color: var(--doka-component--background);
        transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
      }
      .doka--size-input input:focus {
        transition: inherit;
        border: inherit;
        background-color: inherit;
        box-shadow: 0 0 0 2px currentColor;
      }
      .doka--size-input input::-webkit-input-placeholder {
        color: hsla(0, 0%, 100%, 0.3) !important;
        color: var(--doka-editor--color-dim) !important;
      }
      .doka--size-input input::-moz-placeholder {
        color: hsla(0, 0%, 100%, 0.3) !important;
        color: var(--doka-editor--color-dim) !important;
      }
      .doka--size-input input::-ms-input-placeholder {
        color: hsla(0, 0%, 100%, 0.3) !important;
        color: var(--doka-editor--color-dim) !important;
      }
      .doka--size-input input::placeholder {
        color: hsla(0, 0%, 100%, 0.3) !important;
        color: var(--doka-editor--color-dim) !important;
      }
      .doka--size-input input:-ms-input-placeholder {
        color: hsla(0, 0%, 100%, 0.3) !important;
        color: var(--doka-editor--color-dim) !important;
      }
      @supports (
        (-webkit-backdrop-filter: var(--doka-effect--frost)) or
          (backdrop-filter: var(--doka-effect--frost))
      ) {
        .doka--size-input input {
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: var(--doka-effect--frost);
          backdrop-filter: var(--doka-effect--frost);
        }
      }
      .doka--root {
        box-sizing: content-box;
        position: relative;
        overflow: hidden;
        -ms-touch-action: none;
        touch-action: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        height: 100%;
        width: 100%;
        line-height: normal;
        font-size: 16px;
        font-size: var(--doka-font-size);
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
          Arial, sans-serif;
        font-family: var(--doka-font-family);
        font-weight: 450;
        text-align: left;
        text-rendering: optimizeLegibility;
        direction: ltr;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        color: hsla(0, 0%, 100%, 0.9);
        color: var(--doka-editor--color);
        background: rgba(0, 0, 0, 0.99);
        background: var(--doka-editor--background-color);
        contain: strict;
        transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
      }
      .doka--root * {
        box-sizing: inherit;
      }
      .doka--root button {
        box-sizing: border-box;
      }
      .doka--root:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        background: radial-gradient(
          ellipse at center,
          hsla(0, 0%, 100%, 0.15) 0,
          hsla(0, 0%, 100%, 0) 80%
        );
        background: radial-gradient(
          ellipse at center,
          var(--doka-editor--background-ellipse-inner-color) 0,
          var(--doka-editor--background-ellipse-outer-color) 80%
        );
      }
      .doka--markup {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
      }
      .doka--markup .doka--toolbar {
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin: 0 auto;
        padding: 1.25em 2em;
      }
      .doka--markup-utils {
        border: none;
        padding: 0;
      }
      .doka--markup-utils ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: -ms-flexbox;
        display: flex;
      }
      .doka--markup-utils li {
        margin: 0 0.75em;
      }
      .doka--markup-utils input {
        display: none;
      }
      .doka--markup-utils label {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        pointer-events: all;
        height: 2.5em;
      }
      .doka--markup-utils input:checked + label {
        color: #ffd843;
        color: var(--doka-filter-tile-selected--color);
      }
      .doka--toolbar[data-layout="compact"] .doka--markup-utils li {
        margin: 0 0.3125em;
      }
      .doka--markup-tools {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: auto 1em 0;
        padding: 0.3125em 0 0.75em;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: end;
        align-items: flex-end;
        max-height: 3em;
        min-height: 2.0625em;
      }
      .doka--markup-tools[data-active="true"] {
        pointer-events: all !important;
      }
      .doka--markup-tools .doka--button span {
        text-align: left;
      }
      .doka--markup-tools .doka--dropdown > .doka--button {
        color: hsla(0, 0%, 100%, 0.9);
        color: var(--doka-button--color);
        background-color: hsla(0, 0%, 100%, 0.15);
        background-color: var(--doka-button--background);
        border-radius: 9999em;
        line-height: 1.75em;
        margin: 0 0.25em;
        font-size: 0.925em;
      }
      .doka--markup-tools .doka--dropdown > .doka--button > span {
        margin-left: 0.3125em;
      }
      .doka--markup-tools .doka--dropdown {
        margin-top: 0.5em;
      }
      .doka--markup-tools [data-active="false"] > .doka--button {
        display: none;
      }
      .doka--markup-tools .doka--markup-color {
        margin-top: -0.875em;
      }
      .doka--markup-tools .doka--markup-color[data-active="false"] {
        display: none;
      }
      .doka--markup-color {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
      }
      .doka--markup-color,
      .doka--markup-color ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
      .doka--markup-color ul {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
      }
      .doka--markup-color li {
        padding: 0;
        margin: 0 0.125em;
      }
      .doka--markup-color li input {
        display: none;
      }
      .doka--markup-color label {
        display: block;
        overflow: hidden;
        color: transparent;
        width: 1.5em;
        height: 1.5em;
        margin: 0;
        padding: 0;
        font-weight: 400;
        line-height: inherit;
        border-radius: 9999em;
        transition: box-shadow 0.125s ease-out;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
        cursor: inherit;
      }
      .doka--markup-color .doka--color-input input[data-selected="true"] + label,
      .doka--markup-color input:checked + label {
        box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.5), 0 0 0 2px #fff;
      }
      .doka--markup-color .doka--color-input {
        margin: 0 0.125em;
      }
      .doka--color-input {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: 1.5em;
        height: 1.5em;
        border-radius: 9999em;
        color: hsla(0, 0%, 100%, 0.9);
        color: var(--doka-button--color);
        background-color: hsla(0, 0%, 100%, 0.15);
        background-color: var(--doka-button--background);
      }
      .doka--color-input label {
        overflow: hidden;
        background-image: radial-gradient(
            hsla(0, 0%, 100%, 0.3) 33%,
            hsla(0, 0%, 100%, 0) 36%
          ),
          url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23FECD09' d='M12 0h12v24H12z'/%3E%3Cpath fill='%2366AF01' d='M0 12h24v12H0z'/%3E%3Cpath fill='%237300CD' d='M0 0h12v24H0z'/%3E%3Cpath fill='%23E12224' d='M0 0h12v12H0z'/%3E%3Cpath fill='%23FF7D00' d='M12 12L24 0H12z'/%3E%3Cpath fill='%23B400AA' d='M0 12V0l12 12z'/%3E%3Cpath fill='%23039695' d='M12 24h12L12 12z'/%3E%3Cpath fill='%23465FCD' d='M12 24H0l12-12z'/%3E%3C/g%3E%3C/svg%3E");
      }
      .doka--color-input input {
        position: absolute;
        opacity: 0;
        -webkit-appearance: none;
        border: 0;
        padding: 0;
        margin: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 1em;
        min-width: 0;
        min-height: 0;
      }
      .doka--color-input span {
        position: absolute;
        pointer-events: none;
      }
      .doka--color-input .doka--color-visualizer {
        opacity: 0;
        width: 0.375em;
        height: 0.375em;
        border-radius: 1em;
        box-shadow: 0 0 1px 1px #fff, 0 0 0 1.5px rgba(0, 0, 0, 0.7);
        z-index: 1;
      }
      .doka--color-input .doka--color-visualizer[style] {
        opacity: 1;
      }
      .doka--color-input .doka--color-brightness {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 9999em;
        background: #000;
        box-shadow: inset 0 0 0.75em hsla(0, 0%, 100%, 0.25), 0 0 1px #000;
        opacity: 0;
      }
      .doka--root[data-style-viewport~="x-cramped"] .doka--markup-color {
        width: 100%;
      }
      .doka--status-progress {
        display: none;
        background-color: rgba(0, 0, 0, 0.2);
        background-color: var(--doka-status-bubble--color-dim);
        border-radius: 50%;
        pointer-events: none;
      }
      .doka--status-bubble {
        pointer-events: none;
        will-change: transform;
        text-align: center;
      }
      .doka--status-bubble .doka--status-bubble-inner {
        position: relative;
        display: inline-block;
        color: rgba(0, 0, 0, 0.8);
        color: var(--doka-status-bubble--color);
        background-color: hsla(0, 0%, 100%, 0.75);
        background-color: var(--doka-status-bubble--background);
        box-shadow: 0 0.125em 1em rgba(0, 0, 0, 0.2);
        box-shadow: var(--doka-status-bubble--shadow);
        transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
        font-size: 0.875em;
        max-width: 80%;
        margin: 0 auto;
        border-radius: 1em;
        line-height: 1.5;
        text-align: center;
        padding: 0.1875em 1em 0.25em;
      }
      .doka--status-bubble p {
        display: inline;
        margin: 0;
        padding: 0;
      }
      .doka--status-bubble .doka--status-progress:not([data-value="0"]) {
        position: absolute;
        display: block;
        right: 0.35em;
        top: 0.35em;
        width: 1.25em;
        height: 1.25em;
      }
      .doka--status-bubble .doka--status-progress:not([data-value="0"]) + p,
      .doka--status-bubble button + p {
        padding-right: 1.5em;
      }
      .doka--status-bubble button {
        position: relative;
        z-index: 1;
        margin: 0;
        padding: 0;
        border: none;
        font-family: inherit;
        font-size: 1em;
        font-weight: 400;
        outline: transparent;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        pointer-events: all;
        border-radius: 9999em;
        width: 18px;
        height: 18px;
        color: hsla(0, 0%, 100%, 0.75);
        color: var(--doka-status-bubble--background);
        background-color: rgba(0, 0, 0, 0.8);
        background-color: var(--doka-status-bubble--color);
        position: absolute;
        right: 0.375em;
        top: 0.35em;
      }
      .doka--status-bubble button svg {
        width: 0.875em;
        height: 0.875em;
      }
      .doka--status-bubble button:after {
        content: "";
        position: absolute;
        left: -1em;
        right: -1em;
        top: -1em;
        bottom: -1em;
      }
      @supports (
        (-webkit-backdrop-filter: var(--doka-effect--frost)) or
          (backdrop-filter: var(--doka-effect--frost))
      ) {
        .doka--status-bubble-inner {
          -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: var(--doka-effect--frost);
          backdrop-filter: var(--doka-effect--frost);
          background: hsla(0, 0%, 100%, 0.5);
          background: var(--doka-status-bubble-effect--background);
        }
      }
      .doka--toolbar {
        z-index: 3;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 1.25em 0;
        pointer-events: none;
        margin: 0 2em;
      }
      .doka--toolbar > * {
        pointer-events: all;
        margin: 0 0.75em;
        height: 2.5em;
      }
      .doka--toolbar svg {
        display: inline-block;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
      .doka--toolbar svg * {
        transition: opacity 0.25s ease-out, transform 0.5s ease-out;
      }
      .doka--toolbar .doka--button {
        white-space: nowrap;
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
      .doka--root[data-style-viewport~="flow-controls"][data-style-viewport~="single-util"]:not([data-style-viewport~="x-cramped"])
        .doka--toolbar {
        margin: -4.875em 11em 0;
      }
      .doka--toolbar[data-layout="compact"] > * {
        margin: 0 0.5em;
      }
      .doka--root[data-style-viewport~="x-cramped"] .doka--toolbar {
        padding: 1em 0;
      }
      .doka--root[data-style-viewport~="x-cramped"] .doka--toolbar > * {
        margin: 0 0.25em;
      }
      .doka--utils {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex: 1;
        flex: 1;
        padding: 0;
        overflow: hidden;
      }
      .doka--utils > * {
        pointer-events: all;
      }
      .doka--utils > * + * {
        margin-left: 1px;
      }
      .implicit {
        position: absolute;
        overflow: hidden;
        height: 1px;
        width: 1px;
        padding: 0;
        border: 0;
        clip: rect(1px, 1px, 1px, 1px);
        -webkit-clip-path: inset(50%);
        clip-path: inset(50%);
        white-space: nowrap;
      }
      .logo {
        margin: 0;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        font-family: Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
          Helvetica, Arial, sans-serif;
        font-weight: 300;
        font-size: 0.875em;
        pointer-events: all;
        color: #1b1718;
        text-align: left;
      }
      .logo a {
        text-decoration: none;
        padding-top: 0.875em;
        padding-bottom: 0.175em;
        color: inherit;
      }
      .logo a:first-of-type {
        padding-left: 4.4125em;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0 50%;
        background-image: url(/static/assets/doka-logo.svg);
      }
      .logo a:first-of-type > span {
        letter-spacing: 0.06125em;
      }
      .logo a:last-of-type:not(:first-of-type) {
        margin-left: 0.25em;
      }
      body {
        overflow: hidden;
      }
      html {
        background-color: #000;
      }
      .online-editor {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        transition: 1s;
      }
      .doka--edit-status {
        background: none;
        outline: none;
      }
      .doka--root {
        --doka-effect--invert: 100%;
        --doka-color--alpha: #2990ff;
        --doka-color--alpha-dim: #1a80ec;
      }
      .page--header {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        padding: 10vh 0 0;
        text-align: center;
        z-index: 1001;
        pointer-events: none;
        opacity: 1;
        transition: opacity 0.25s;
      }
      .page--header .logo {
        display: inline-block;
        font-size: 1.5em;
      }
      .page--header .logo a:first-of-type {
        padding-left: 4.1em;
      }
      .page--header p {
        font-size: 1.125em;
        color: #777;
      }
      @media (max-height: 36em) {
        .page--header .logo {
          font-size: 1em;
        }
        .page--header p {
          font-size: 0.875em;
        }
      }
      input[type="file"] {
        outline: none;
      }
      .online-editor--intro-panel {
        background: #fff;
        position: absolute;
        z-index: 1000;
        pointer-events: none;
        left: calc(50% - 10em);
        top: calc(50% - 8em);
        width: 20em;
        box-sizing: border-box;
        padding: 2em 1.25em 1em;
        border-radius: 1em;
        text-align: center;
        box-shadow: 0 1.25em 1em -1em rgba(0, 0, 0, 0.25),
          0 1em 5em rgba(0, 0, 0, 0.25);
      }
      .online-editor--intro-panel h1 {
        display: -ms-inline-flexbox;
        display: inline-flex;
        margin: 1.25em auto;
        transform: scale(0.99);
      }
      .online-editor--intro-panel p {
        margin: 0 auto;
        font-size: 0.9375em;
        max-width: 15em;
      }
      .online-editor--intro-panel p:first-of-type {
        margin-bottom: 1.5em;
      }
      .online-editor--intro-panel p:last-of-type {
        margin: 1em 0 0.5em;
        font-size: 0.75em;
        color: #999;
        max-width: none;
      }
      .online-editor--intro-panel label {
        text-decoration: none;
        font-weight: 400;
        background: linear-gradient(to bottom right, #7c5bf5, #d2413d);
        color: #fff;
        pointer-events: all;
        border-radius: 9999em;
        padding: 0 0.875em;
        line-height: 2;
        white-space: nowrap;
        display: inline-block;
        margin-bottom: 1.5em;
        cursor: pointer;
        box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.1);
      }
      .online-editor--resources {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin: 1.25em -10%;
      }
      .online-editor--resources li {
        position: relative;
        -ms-flex: 1;
        flex: 1;
        padding: 0;
        margin: 0 -2.5%;
      }
      .online-editor--resources li:first-child {
        transform: rotate(4deg) scale(0.9);
      }
      .online-editor--resources li:nth-child(2) {
        transform: rotate(-4deg) scale(0.8);
        z-index: 3;
      }
      .online-editor--resources li:nth-child(3) {
        transform: rotate(-1deg) scale(0.95);
        z-index: 2;
      }
      .online-editor--resources li:nth-child(4) {
        transform: rotate(4deg) scale(0.85);
      }
      .online-editor--resources li:nth-child(5) {
        transform: rotate(-3deg) scale(0.8);
      }
      .online-editor--resources button {
        display: block;
        width: 100%;
        height: 100%;
        border: none;
        padding: 0 0 100%;
        background: none;
        transform: scale(1);
        pointer-events: all;
        outline: transparent;
      }
      .online-editor--resources button:active,
      .online-editor--resources button:focus {
        transition-duration: 0.25s;
        transform: scale(0.95);
      }
      .online-editor--resources img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 1em;
        box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.2);
        border: 3px solid #fff;
        pointer-events: none;
        background: #fff;
      }
      .page--footer {
        opacity: 0;
      }
      .online-editor--intro-panel {
        transform-origin: center center;
      }
      body[data-state="waiting"] .page--footer {
        opacity: 0;
        pointer-events: none;
      }
      body[data-state="waiting"] .page--header {
        opacity: 1;
        transform: translateY(0);
        transition: transform 1s ease-out, opacity 0.5s ease-out 0.5s;
      }
      body[data-state="waiting"] .online-editor--intro-panel {
        opacity: 1;
        transform: scale(1);
        transition: transform 1s ease-out, opacity 0.5s ease-out 0.5s;
      }
      body[data-state="editing"] .page--footer {
        opacity: 1;
      }
      body[data-state="editing"] .page--header {
        pointer-events: none;
        opacity: 0;
        transform: translateY(-1em);
        transition: transform 1s ease-in, opacity 0.25s ease-in 0.125s;
      }
      body[data-state="editing"] .page--header * {
        pointer-events: none !important;
      }
      body[data-state="editing"] .online-editor--intro-panel {
        opacity: 0;
        transform: scale(0.75);
        transition: transform 1s ease-in, opacity 0.25s ease-in 0.125s;
      }
      body[data-state="editing"] .online-editor--intro-panel * {
        pointer-events: none;
      }
      .page--main {
        position: relative;
        z-index: 1;
      }
      .page--footer {
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0;
        right: 0;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: center;
        align-items: center;
        min-height: 4.5em;
        padding: 0 1.5em;
        display: none;
        pointer-events: none;
      }
      @media (min-width: 50em) {
        .page--footer {
          display: -ms-flexbox;
          display: flex;
        }
        .doka--container {
          padding-top: 4.5rem;
          height: calc(100% - 4.5rem);
        }
      }
      .refer {
        display: inline-block;
        color: rgba(0, 0, 0, 0.95);
        background: rgba(0, 0, 0, 0.1);
        padding: 0.125em 0.5em;
        border-radius: 0.25em;
        font-size: 0.875em;
        pointer-events: all;
        text-decoration: none;
      }
      @media (prefers-color-scheme: dark) {
        .doka--root {
          --doka-effect--invert: 0%;
          --doka-color--alpha: #ffd843;
          --doka-color--alpha-dim: #ffc343;
        }
        .page--header,
        footer {
          color: #fff;
        }
        .page--header p {
          color: #bbb;
        }
        .page--header .logo,
        footer .logo {
          color: inherit;
        }
        .page--header .logo a:first-of-type,
        footer .logo a:first-of-type {
          background-image: url(/static/assets/doka-logo-inverted.svg);
        }
        .refer {
          color: hsla(0, 0%, 100%, 0.95);
          background: hsla(0, 0%, 100%, 0.15);
        }
      }
      
  }

  .doka--view-stack {
    pointer-events: none;
  }
  .doka--view-stack > * {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    outline: transparent;
  }
  .doka--view-stack [data-view-active="false"] * {
    pointer-events: none !important;
  }
  .doka--view-stack [data-view-active="true"] {
    z-index: 1;
  }
  .doka--view-stack [data-view-hidden="true"] {
    display: none !important;
  }
  .doka--button-app {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    font-family: inherit;
    font-size: 1em;
    outline: transparent;
    color: hsla(0, 0%, 100%, 0.9);
    color: var(--doka-button--color);
    background-color: hsla(0, 0%, 100%, 0.15);
    background-color: var(--doka-button--background);
    border-radius: 9999em;
    border-radius: var(--doka-button-main--radius);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    line-height: 2.5;
    padding: 0 1.5em;
    font-weight: 500;
    transition: transform 0.15s ease-out, background-color 0.25s ease-out,
      color 0.25s ease-out;
    transform: scale(1);
  }
  .doka--button-app svg {
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin: 0 auto;
    display: inline-block;
  }
  .doka--button-app svg + span {
    margin-left: 0.5em;
  }
  .doka--button-app span {
    font-size: 0.875em;
  }
  .doka--button-app:focus,
  .doka--button-app:hover {
    background-color: hsla(0, 0%, 100%, 0.25);
    background-color: var(--doka-button-hover--background);
  }
  .doka--button-app:active {
    transition-duration: 0.25s;
    transform: scale(0.95);
  }
  @supports (
    (-webkit-backdrop-filter: var(--doka-effect--frost)) or
      (backdrop-filter: var(--doka-effect--frost))
  ) {
    .doka--button-app {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: var(--doka-effect--frost);
      backdrop-filter: var(--doka-effect--frost);
    }
  }
  .doka--button-app.doka--button-icon-only {
    width: 2.25em;
    height: 2.25em;
    padding: 0;
  }
  .doka--button-action-confirm {
    color: #000;
    color: var(--doka-button-primary--color);
    background-color: #ffd843;
    background-color: var(--doka-button-primary--background);
    font-weight: 600;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }
  .doka--button-action-confirm:focus,
  .doka--button-action-confirm:hover {
    background-color: #ffc343;
    background-color: var(--doka-button-primary-hover--background);
  }
  .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
    .doka--button-app {
    font-size: 0.85em;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }
  .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
    .doka--button-app
    svg {
    width: 1.25em;
    height: 1.25em;
  }
  .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
    .doka--button-action-confirm {
    font-size: 0.75em;
  }
  .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
    .doka--button-app:not(.doka--button-action-confirm) {
    color: hsla(0, 0%, 100%, 0.9);
    color: var(--doka-button--color);
    background-color: transparent;
  }
  .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
    .doka--button-app.doka--button-icon-only
    svg {
    width: 1.75em;
    height: 1.75em;
  }
  .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
    .doka--button-app.doka--button-icon-fallback {
    width: 2.5em;
    height: 2.5em;
    padding: 0;
  }
  .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
    .doka--button-app.doka--button-icon-fallback
    span {
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  .doka--button-icon-fallback svg {
    display: none;
  }
  .doka--button-icon-fallback svg + span {
    margin-left: 0;
  }
  .doka--root[data-style-viewport*="x-cramped"][data-style-viewport*="multi-util"]
    .doka--button-icon-fallback
    svg {
    display: block;
  }
  .doka--root[data-style-viewport*="x-cramped"][data-style-viewport*="multi-util"]
    .doka--button-icon-fallback
    svg
    + span {
    margin-left: 0;
  }
  .doka--button-icon-only {
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .doka--button-icon-only span {
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  .doka--button-tab {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    border: none;
    font-family: inherit;
    font-size: 1em;
    outline: transparent;
    line-height: inherit;
    color: hsla(0, 0%, 100%, 0.9);
    color: var(--doka-button--color);
    border-radius: 0;
    border-radius: var(--doka-button-tab--radius);
    width: 5em;
    max-width: 5em;
    padding-top: 0.875em;
    padding-bottom: 0.75em;
    background-color: transparent;
    font-weight: 500;
    transition: transform 0.15s ease-out, background-color 0.25s ease-out,
      color 0.25s ease-out;
    transform: scale(1);
  }
  .doka--button-tab svg {
    display: inline;
    margin-bottom: 0.325em;
  }
  .doka--button-tab span {
    display: block;
    font-size: 0.875em;
  }
  .doka--button-tab:focus,
  .doka--button-tab:hover {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: var(--doka-effect--frost);
    backdrop-filter: var(--doka-effect--frost);
  }
  .doka--button-tab:focus,
  .doka--button-tab:hover,
  .doka--button-tab[data-active="true"] {
    background-color: hsla(0, 0%, 100%, 0.15);
    background-color: var(--doka-button--background);
  }
  .doka--button-tab[data-active="true"]:focus,
  .doka--button-tab[data-active="true"]:hover {
    background-color: hsla(0, 0%, 100%, 0.25);
    background-color: var(--doka-button-hover--background);
  }
  .doka--button-tab:active {
    transition-duration: 0.25s;
    transform: scale(0.95);
  }
  .doka--button-tab .doka--icon-resize-arrow-ne,
  .doka--button-tab .doka--icon-resize-arrow-sw {
    transition: opacity 0.25s ease-out;
  }
  .doka--button-tab[data-scale-direction="down"] .doka--icon-resize-arrow-ne {
    opacity: 0;
  }
  .doka--button-tab[data-scale-direction="down"] .doka--icon-resize-arrow-sw,
  .doka--button-tab[data-scale-direction="up"] .doka--icon-resize-arrow-ne {
    opacity: 1;
  }
  .doka--button-tab[data-scale-direction="up"] .doka--icon-resize-arrow-sw {
    opacity: 0;
  }
  @supports (
    (-webkit-backdrop-filter: var(--doka-effect--frost)) or
      (backdrop-filter: var(--doka-effect--frost))
  ) {
    .doka--button-tab[data-active="true"] {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: var(--doka-effect--frost);
      backdrop-filter: var(--doka-effect--frost);
    }
  }
  .doka--utils[data-layout="compact"] .doka--button-tab {
    height: 4.6875em;
  }
  .doka--utils[data-layout="compact"] .doka--button-tab span {
    display: none;
  }
  .doka--utils[data-layout="compact"] .doka--button-tab svg {
    margin-bottom: 0;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--utils {
    padding: 0 1px;
  }
  .doka--root[data-style-viewport*="x-cramped"]
    .doka--utils[data-util-count="5"]
    .doka--button-tab {
    width: 20%;
  }
  .doka--root[data-style-viewport*="x-cramped"]
    .doka--utils[data-util-count="5"]
    .doka--button-tab
    span {
    display: none;
  }
  .doka--root[data-style-viewport*="x-cramped"]
    .doka--utils[data-util-count="4"]
    .doka--button-tab {
    width: 25%;
  }
  .doka--root[data-style-viewport*="x-cramped"]
    .doka--utils[data-util-count="3"]
    .doka--button-tab {
    width: 33.333%;
  }
  .doka--root[data-style-viewport*="x-cramped"]
    .doka--utils[data-util-count="2"]
    .doka--button-tab {
    width: 50%;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--button-tab {
    min-width: 2em;
    max-width: 3.5em;
    height: 3.25em;
    padding: 0.325em 0;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 0 1px var(--doka-tab--border-color);
  }
  .doka--root[data-style-viewport*="x-cramped"]
    .doka--button-tab:first-child:before,
  .doka--root[data-style-viewport*="x-cramped"]
    .doka--button-tab:last-child:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    pointer-events: none;
  }
  .doka--root[data-style-viewport*="x-cramped"]
    .doka--button-tab:first-child:before {
    box-shadow: -1px 0 0 0 rgba(0, 0, 0, 0.15);
    box-shadow: -1px 0 0 0 var(--doka-tab--border-color);
  }
  .doka--root[data-style-viewport*="x-cramped"]
    .doka--button-tab:last-child:before {
    box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.15);
    box-shadow: 1px 0 0 0 var(--doka-tab--border-color);
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--button-tab svg {
    width: 1em;
    margin: 0 auto -0.125em;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--button-tab span {
    font-size: 0.5625em;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--button-tab:active {
    transform: scale(1);
  }
  .doka--root[data-style-viewport*="x-cramped"]
    .doka--button-tab[data-active="true"] {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }
  .doka--root[data-style-viewport*="x-cramped"]
    .doka--button-tab[data-active="true"]:focus,
  .doka--root[data-style-viewport*="x-cramped"]
    .doka--button-tab[data-active="true"]:hover {
    background-color: hsla(0, 0%, 100%, 0.15);
    background-color: var(--doka-button--background);
  }
  .doka--button-tool {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    border: none;
    font-family: inherit;
    font-size: 1em;
    font-weight: 400;
    outline: transparent;
    border-radius: 0;
    border-radius: var(--doka-button-tool--radius);
    color: hsla(0, 0%, 100%, 0.9);
    color: var(--doka-button--color);
    display: -ms-flexbox;
    display: flex;
    padding: 0 0.75em 0 0.5em;
    -ms-flex-align: center;
    align-items: center;
    background-color: transparent;
    transition: transform 0.15s ease-out, background-color 0.25s ease-out,
      color 0.25s ease-out;
    transform: scale(1);
  }
  .doka--button-tool span {
    font-size: 0.875em;
    margin-left: 0.5em;
  }
  .doka--button-tool:focus,
  .doka--button-tool:hover {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: var(--doka-effect--frost);
    backdrop-filter: var(--doka-effect--frost);
    background-color: hsla(0, 0%, 100%, 0.15);
    background-color: var(--doka-button--background);
  }
  .doka--button-tool:active {
    transition-duration: 0.25s;
    transform: scale(0.95);
  }
  [data-layout="compact"] .doka--button-tool {
    padding: 0 0.5em;
    border-radius: 0.25em;
    background-color: hsla(0, 0%, 100%, 0.1);
    background-color: var(--doka-button--background-dim);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: var(--doka-effect--frost);
    backdrop-filter: var(--doka-effect--frost);
    width: 2.5em;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
  [data-layout="compact"] .doka--button-tool:focus,
  [data-layout="compact"] .doka--button-tool:hover {
    background-color: hsla(0, 0%, 100%, 0.15);
    background-color: var(--doka-button--background);
  }
  [data-layout="compact"] .doka--button-tool span {
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  .doka--button-zoom {
    position: static;
    margin: 0;
    padding: 0;
    border: none;
    font-family: inherit;
    font-size: 1em;
    outline: transparent;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 2.5;
    font-weight: 500;
    transition: transform 0.15s ease-out, background-color 0.25s ease-out,
      color 0.25s ease-out;
    border-radius: 9999em;
    height: 2.5em;
    width: 2.5em;
    color: rgba(0, 0, 0, 0.85);
    color: var(--doka-button-zoom--color);
    background-color: hsla(0, 0%, 100%, 0.85);
    background-color: var(--doka-button-zoom--background);
  }
  .doka--button-zoom span {
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  .doka--button-zoom:focus,
  .doka--button-zoom:hover {
    background-color: hsla(0, 0%, 100%, 0.95);
    background-color: var(--doka-button-zoom-hover--background);
  }
  @supports (
    (-webkit-backdrop-filter: var(--doka-effect--frost)) or
      (backdrop-filter: var(--doka-effect--frost))
  ) {
    .doka--button-zoom {
      background-color: hsla(0, 0%, 100%, 0.6);
      background-color: var(--doka-button-zoom-effect--background);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: var(--doka-effect--frost);
      backdrop-filter: var(--doka-effect--frost);
    }
    .doka--button-zoom:focus,
    .doka--button-zoom:hover {
      background-color: hsla(0, 0%, 100%, 0.75);
      background-color: var(--doka-button-zoom-effect-hover--background);
    }
  }
  .doka--checkable {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    height: 2.15625em;
    line-height: 1.5;
    color: hsla(0, 0%, 100%, 0.9);
    color: var(--doka-component--color);
    transform: scale(1);
  }
  .doka--checkable input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: 0;
    opacity: 0;
    outline: transparent;
  }
  .doka--checkable label {
    display: inline-block;
    cursor: pointer;
    transition: opacity 0.125s ease-out;
    font-size: 0.875em;
    margin: 0;
    color: inherit;
  }
  .doka--checkable input:active + label,
  .doka--checkable input:focus + label {
    background-color: hsla(0, 0%, 100%, 0.15);
    background-color: var(--doka-component--background);
    border-radius: 0.5em;
  }
  .doka--checkable svg {
    display: block;
    margin: 0;
  }
  .doka--checkable path {
    transition: transform 0.1s ease-out;
  }
  .doka--checkable:active {
    transition-duration: 0.25s;
    transform: scale(0.925);
  }
  .doka--color {
    height: calc(100% - 3em);
    width: calc(100% - 2em);
    left: 1em;
    top: 1em;
  }
  .doka--color .doka--color-form {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 1em 0 0;
    height: 6.5em;
    box-sizing: border-box;
    margin: 0;
    contain: layout size style;
  }
  .doka--color .doka--range-input {
    pointer-events: all;
    margin: 0 0.5em;
    max-width: 10em;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex: 1;
    flex: 1;
  }
  .doka--color .doka--range-input label {
    color: inherit;
    margin-top: 0.25em;
    font-size: 0.75em;
    -ms-flex-item-align: center;
    align-self: center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  .doka--color .doka--range-input svg {
    display: inline-block;
    width: 1.25em;
    margin-right: 0.5em;
  }
  .doka--color .doka--range-input [stroke-width] {
    stroke-width: 2.5;
  }
  @media (-ms-high-contrast: none) {
    .doka--color-form .doka--range-input,
    ::-ms-backdrop {
      -ms-flex: none;
      flex: none;
      width: 10em;
    }
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--color {
    top: 2em;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--color-form {
    padding: 0.5em 0 0;
    height: 5em;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -0.5em;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    width: 100%;
    max-width: 25em;
    padding: 0;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--range-input {
    width: calc(50% - 1em);
    max-width: none;
    -ms-flex: auto;
    flex: auto;
    -ms-flex-direction: row;
    flex-direction: row;
    margin: 0 0.25em;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--range-input label {
    margin: 0;
    width: 1.25em;
    overflow: hidden;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--range-input label svg {
    margin: 0;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--range-input label span {
    display: none;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--range-input:nth-child(2n) {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
  .doka--container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }
  .doka--container[data-limit-overflow="true"] {
    overflow: hidden;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--container {
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .doka--content {
    position: relative;
    -ms-flex: 1;
    flex: 1;
  }
  .doka--content > * {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .doka--view-stack {
    z-index: 2;
  }
  .doka--image {
    z-index: 1;
  }
  .doka--instructions-bubble {
    color: #fff;
    color: var(--doka-instruction-bubble--color);
    background: radial-gradient(
      closest-side ellipse,
      rgba(0, 0, 0, 0.22) 20%,
      rgba(0, 0, 0, 0.21) 35%,
      rgba(0, 0, 0, 0.18) 50%,
      rgba(0, 0, 0, 0.1) 70%,
      rgba(0, 0, 0, 0.05) 80%,
      rgba(0, 0, 0, 0.01) 90%,
      transparent
    );
    background: var(--doka-instruction-bubble--background);
    filter: drop-shadow(0 0.125em 0.75em black);
    filter: drop-shadow(var(--doka-instruction-bubble--color-shadow));
    position: absolute;
    left: 0;
    top: 0;
    max-width: 15em;
    line-height: 1.5;
    text-align: center;
    padding: 3em 4em 4em;
    font-size: 0.75em;
    pointer-events: none;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
  }
  .doka--instructions-bubble svg:first-child {
    margin-bottom: 0.5em;
  }
  .doka--crop-mask {
    position: absolute;
    left: 0;
    top: 0;
    will-change: transform;
    pointer-events: none;
  }
  .doka--crop-rect {
    z-index: 2;
    position: relative;
  }
  .doka--crop-rect-corner {
    color: transparent;
    position: absolute;
    pointer-events: all;
    z-index: 3;
    padding: 0;
    margin: 0;
    border: none;
    text-indent: 0;
    width: 20px;
    height: 20px;
    left: -10px;
    top: -10px;
    will-change: transform;
  }
  .doka--crop-rect-corner:after {
    position: absolute;
    left: -1em;
    right: -1em;
    top: -1em;
    bottom: -1em;
    content: "";
  }
  .doka--crop-rect-corner-ne,
  .doka--crop-rect-corner-sw {
    cursor: nesw-resize !important;
  }
  .doka--crop-rect-corner-nw,
  .doka--crop-rect-corner-se {
    cursor: nwse-resize !important;
  }
  .doka--root[data-style-crop-corner="circle"] .doka--crop-rect-corner {
    background: #fff;
    background: var(--doka-crop-corner--color);
    box-shadow: 0 0.0625em 0.125em rgba(0, 0, 0, 0.2);
    box-shadow: var(--doka-crop-corner--shadow);
    border-radius: 9999em;
    transition: background-color 0.25s ease-in-out;
  }
  .doka--root[data-style-crop-corner="line"] .doka--crop-rect-corner:before {
    position: absolute;
    width: 2.5em;
    height: 2.5em;
    pointer-events: none;
    transition: opacity 0.25s ease-out;
    content: "";
  }
  .doka--root[data-style-crop-corner="line"] .doka--crop-rect-corner-nw:before {
    box-shadow: inset 0.25em 0.25em 0 0 #fff;
    box-shadow: inset 0.25em 0.25em 0 0 var(--doka-crop-corner--color);
    left: 0.4375em;
    top: 0.4375em;
  }
  .doka--root[data-style-crop-corner="line"] .doka--crop-rect-corner-ne:before {
    box-shadow: inset -0.25em 0.25em 0 0 #fff;
    box-shadow: inset -0.25em 0.25em 0 0 var(--doka-crop-corner--color);
    right: 0.4375em;
    top: 0.4375em;
  }
  .doka--root[data-style-crop-corner="line"] .doka--crop-rect-corner-se:before {
    box-shadow: inset -0.25em -0.25em 0 0 #fff;
    box-shadow: inset -0.25em -0.25em 0 0 var(--doka-crop-corner--color);
    right: 0.4375em;
    bottom: 0.4375em;
  }
  .doka--root[data-style-crop-corner="line"] .doka--crop-rect-corner-sw:before {
    box-shadow: inset 0.25em -0.25em 0 0 #fff;
    box-shadow: inset 0.25em -0.25em 0 0 var(--doka-crop-corner--color);
    left: 0.4375em;
    bottom: 0.4375em;
  }
  .doka--root[data-style-crop-corner="line"]
    .doka--crop-rect[data-indicator-size="none"]
    .doka--crop-rect-corner:before {
    opacity: 0;
  }
  .doka--crop-rect-focal-line {
    background: hsla(0, 0%, 100%, 0.5);
    background: var(--doka-crop-focal-line--color);
    height: 100px;
    width: 100px;
    z-index: 3;
    pointer-events: none;
    will-change: transform, opacity;
  }
  .doka--crop-rect-edge,
  .doka--crop-rect-focal-line {
    position: absolute;
    left: 0;
    top: 0;
    transform-origin: left top;
  }
  .doka--crop-rect-edge {
    padding: 0;
    border: 0;
    background: transparent;
    z-index: 2;
    pointer-events: all;
    will-change: transform;
  }
  .doka--crop-rect-edge:focus {
    outline: transparent;
  }
  .doka--crop-rect-edge:focus:after {
    position: absolute;
    content: "";
    background-color: hsla(0, 0%, 100%, 0.5);
    background-color: var(--doka-crop-edge-focus--color);
  }
  .doka--crop-rect-edge-n,
  .doka--crop-rect-edge-s {
    width: 100px;
    top: -20px;
    height: 40px;
    cursor: ns-resize !important;
  }
  .doka--crop-rect-edge-n:after,
  .doka--crop-rect-edge-s:after {
    left: 0;
    right: 0;
    top: 17px;
    width: 100%;
    height: 7px;
  }
  .doka--crop-rect-edge-e,
  .doka--crop-rect-edge-w {
    cursor: ew-resize !important;
    height: 100px;
    left: -20px;
    width: 40px;
  }
  .doka--crop-rect-edge-e:after,
  .doka--crop-rect-edge-w:after {
    top: 0;
    bottom: 0;
    left: 17px;
    height: 100%;
    width: 7px;
  }
  .doka--root[data-style-viewport*="touch"][data-style-crop-corner="circle"]
    .doka--crop-rect-corner:after {
    border-radius: 50%;
    opacity: 0;
    background: #fff;
    background: var(--doka-crop-corner-focus--color);
    box-shadow: 0 0 0 1em #fff;
    box-shadow: 0 0 0 1em var(--doka-crop-corner-focus--color);
  }
  .doka--root[data-style-viewport*="touch"][data-style-crop-corner="circle"]
    .doka--crop-rect-corner:active:after {
    opacity: 0.25;
  }
  .doka--root[data-style-viewport*="touch"] .doka--crop-rect-edge:active {
    outline: transparent;
  }
  .doka--root[data-style-viewport*="touch"] .doka--crop-rect-edge:active:after {
    position: absolute;
    background-color: hsla(0, 0%, 100%, 0.5);
    background-color: var(--doka-crop-edge-focus--color);
    content: "";
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--crop-rect-corner {
    width: 16px;
    height: 16px;
    left: -8px;
    top: -8px;
  }
  .doka--root[data-style-viewport*="x-cramped"][data-style-crop-corner="line"]
    .doka--crop-rect-corner-nw:before {
    left: 0.325em;
    top: 0.325em;
  }
  .doka--root[data-style-viewport*="x-cramped"][data-style-crop-corner="line"]
    .doka--crop-rect-corner-ne:before {
    right: 0.325em;
    top: 0.325em;
  }
  .doka--root[data-style-viewport*="x-cramped"][data-style-crop-corner="line"]
    .doka--crop-rect-corner-se:before {
    right: 0.325em;
    bottom: 0.325em;
  }
  .doka--root[data-style-viewport*="x-cramped"][data-style-crop-corner="line"]
    .doka--crop-rect-corner-sw:before {
    left: 0.325em;
    bottom: 0.325em;
  }
  .doka--crop-resizer {
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999999;
    background: transparent;
    pointer-events: none;
  }
  .doka--crop-resizer div {
    position: absolute;
    cursor: none;
    left: 0;
    top: 0;
    width: 44px;
    height: 44px;
    margin-left: -22px;
    margin-top: -22px;
    background: hsla(0, 0%, 100%, 0.85);
    border-radius: 22px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    pointer-events: all;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: var(--doka-effect--frost);
    backdrop-filter: var(--doka-effect--frost);
    opacity: 0;
    transition: opacity 0.25s ease-out, visibility 0.25s ease-out;
  }
  .doka--crop-resizer[data-state="multi-touch"] {
    visibility: visible;
    cursor: none;
    pointer-events: all;
  }
  .doka--crop-resizer[data-state="multi-touch"] div {
    opacity: 1;
  }
  .doka--crop-rotator {
    position: relative;
    height: 3.5em;
    margin-top: -0.5em;
    margin-bottom: 0.5em;
    z-index: 2;
    pointer-events: all;
  }
  .doka--crop-rotator-center {
    left: 50%;
    position: absolute;
    overflow: hidden;
    width: 2em;
    top: 0.325em;
    height: 1.5em;
    font-size: 0.75em;
    cursor: pointer;
    border: none;
    padding: 0;
    margin: 0 0 0 -1em;
    background: transparent;
    opacity: 0.3;
    z-index: 2;
    color: inherit;
  }
  .doka--crop-rotator-center span {
    color: transparent;
  }
  .doka--crop-rotator-center:after {
    position: absolute;
    left: 50%;
    height: 0.85em;
    border-radius: 9999em;
    width: 1.5px;
    color: inherit;
    background: currentColor;
    transition: background-color 0.25s ease-in-out;
    margin-left: -0.75px;
    top: 50%;
    margin-top: -0.55em;
    pointer-events: none;
    content: "";
  }
  .doka--crop-rotator-center:focus {
    outline: transparent;
    opacity: 0.7;
  }
  .doka--crop-rotator-bar {
    position: absolute;
    left: 1em;
    right: 1em;
    bottom: 0;
    top: 0;
    z-index: 1;
    cursor: ew-resize;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .doka--crop-rotator-line-mask {
    height: 100%;
    overflow: hidden;
    max-width: 30em;
    margin: 0 auto;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--crop-rotator {
    margin-top: -0.5em;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--crop-rotator-line-mask {
    max-width: 14em;
  }
  @supports ((-webkit-mask-image: url("")) or (mask-image: url(""))) and
    (mix-blend-mode: screen) {
    .doka--crop-rotator-line-mask,
    .doka--root[data-style-viewport*="x-cramped"] .doka--crop-rotator-line-mask {
      max-width: 40em;
      margin: 0 auto;
      -webkit-mask: linear-gradient(
        90deg,
        transparent 15%,
        #000 30%,
        #000 70%,
        transparent 85%
      );
      mask: linear-gradient(
        90deg,
        transparent 15%,
        #000 30%,
        #000 70%,
        transparent 85%
      );
    }
  }
  .doka--crop-rotator-line {
    pointer-events: none;
    height: 100%;
  }
  .doka--crop-rotator-line svg {
    position: absolute;
    left: 50%;
    margin-left: -500px;
    width: 1000px;
  }
  .doka--crop-rotator-line text {
    font-weight: 400;
    font-size: 2px;
    opacity: 0.35;
  }
  .doka--crop-rotator-line circle {
    opacity: 0.4;
  }
  .doka--crop-rotator-line circle[r="0.5"] {
    opacity: 0.9;
  }
  .doka--crop-rotator:focus {
    outline: transparent;
  }
  .doka--crop-rotator:focus .doka--crop-rotator-line circle,
  .doka--crop-rotator:focus .doka--crop-rotator-line text {
    fill: currentColor;
  }
  .doka--crop-size {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.85);
    color: var(--doka-size-indicator--color);
    background: hsla(0, 0%, 100%, 0.95);
    background: var(--doka-size-indicator--background);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: var(--doka-effect--frost);
    backdrop-filter: var(--doka-effect--frost);
    border-radius: 0.25em;
    border-radius: var(--doka-size-indicator--radius);
    line-height: 1;
    white-space: nowrap;
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
    transform-origin: center center;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    pointer-events: none;
    font-variant-numeric: tabular-nums;
    -webkit-font-feature-settings: "tnum";
    font-feature-settings: "tnum";
    font-family: Tahoma, Geneva, Verdana, sans-serif;
  }
  .doka--crop-size-info {
    font-size: 0.6875em;
    padding: 0.35em 0.625em;
  }
  .doka--crop-resize-percentage {
    padding-right: 0.4375em;
    margin-right: -0.25em;
    border-right: 1px solid rgba(0, 0, 0, 0.15);
    border-right: 1px solid var(--doka-size-indicator--edge);
  }
  .doka--crop-resize-percentage:empty {
    display: none;
  }
  .doka--crop-size-multiply {
    opacity: 0.75;
    margin: 0 0.175em;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--crop-size-info {
    font-size: 0.5em;
    padding: 0.5em 0.875em;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--crop-resize-percentage {
    padding-right: 0.4em;
  }
  .doka--crop-subject {
    position: relative;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    margin: 0.25em 1.5em 1.5em;
    contain: layout size style;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--crop-subject {
    margin: 0 2em 1em;
  }
  .doka--zoom-wrapper {
    position: relative;
    z-index: 2;
    pointer-events: all;
  }
  .doka--button-zoom {
    position: absolute;
    z-index: 2;
    left: -1.25em;
    top: -1.25em;
  }
  .doka--dropdown,
  .doka--view-stack .doka--crop {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .doka--dropdown {
    position: relative;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .doka--dropdown > .doka--button {
    height: 100%;
  }
  .doka--dropdown-list {
    position: absolute;
    z-index: 3;
    padding: 0;
    top: 100%;
    margin: -0.125em 0 0;
    min-width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 0.1625em;
    background-color: hsla(0, 0%, 100%, 0.85);
    background-color: var(--doka-component-alt--background);
    box-shadow: 0 0.75em 1em -0.75em rgba(0, 0, 0, 0.4),
      0 1em 2em 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--doka-component-alt--shadow);
  }
  .doka--dropdown-list .doka--button {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    font-family: inherit;
    font-size: 1em;
    outline: transparent;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    white-space: nowrap;
    border: none;
    line-height: 2.5;
    margin: 3px;
    padding: 0 0.75em 0 0.5em;
    border-radius: 0.125em;
    color: rgba(0, 0, 0, 0.85);
    color: var(--doka-component-alt--color);
    background-color: transparent;
    transform: none;
  }
  .doka--dropdown-list .doka--button span {
    font-size: 0.875em;
    text-align: left;
    -ms-flex: 1;
    flex: 1;
  }
  .doka--dropdown-list .doka--button svg + span {
    margin-left: 0.5em;
  }
  .doka--dropdown-list .doka--button svg {
    display: inline-block;
    margin: 0;
  }
  .doka--dropdown-list .doka--button:active,
  .doka--dropdown-list .doka--button:focus,
  .doka--dropdown-list .doka--button:hover {
    background-color: rgba(0, 0, 0, 0.1);
    background-color: var(--doka-dropdown-hover--background);
  }
  .doka--dropdown-list .doka--button:active:hover,
  .doka--dropdown-list .doka--button:focus:hover {
    background-color: rgba(0, 0, 0, 0.15);
    background-color: var(--doka-dropdown-hover-active--background);
  }
  .doka--dropdown-list .doka--button:active {
    transform: none;
  }
  .doka--dropdown-list .doka--button:first-child,
  .doka--dropdown-list .doka--button:last-child {
    border-radius: inherit;
  }
  .doka--dropdown-list .doka--button:first-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .doka--dropdown-list .doka--button:last-child {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
  .doka--dropdown-list .doka--button + .doka--button {
    margin-top: -2px;
  }
  .doka--dropdown-list [aria-selected="true"] {
    color: hsla(0, 0%, 100%, 0.9);
    color: var(--doka-dropdown-selected--color);
    background-color: rgba(0, 0, 0, 0.9);
    background-color: var(--doka-dropdown-selected--background);
  }
  .doka--dropdown-list [aria-selected="true"]:active,
  .doka--dropdown-list [aria-selected="true"]:focus,
  .doka--dropdown-list [aria-selected="true"]:hover {
    background-color: rgba(0, 0, 0, 0.8);
    background-color: var(--doka-dropdown-selected-hover--background);
  }
  .doka--dropdown-list [aria-selected="true"]:active:hover,
  .doka--dropdown-list [aria-selected="true"]:focus:hover {
    background-color: rgba(0, 0, 0, 0.6);
    background-color: var(--doka-dropdown-selected-active-hover--background);
  }
  @supports (
    (-webkit-backdrop-filter: var(--doka-effect--frost)) or
      (backdrop-filter: var(--doka-effect--frost))
  ) {
    .doka--dropdown-list {
      background-color: hsla(0, 0%, 100%, 0.6);
      background-color: var(--doka-component-alt-effect--background);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: var(--doka-effect--frost);
      backdrop-filter: var(--doka-effect--frost);
    }
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--dropdown {
    position: static;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--dropdown-list {
    position: absolute;
    min-width: auto;
    top: 3em;
    left: 3em;
    right: 3em;
    max-width: 15em;
    margin: 0 auto;
  }
  @media (-ms-high-contrast: none) {
    .doka--root .doka--dropdown-list,
    ::-ms-backdrop {
      min-width: 10em;
    }
  }
  .doka--edit-status {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    contain: strict;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.25s ease-out;
    background: hsla(0, 0%, 100%, 0.1);
    background: var(--doka-status--background);
  }
  .doka--edit-status .doka--status-bubble {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
  }
  .doka--edit-status[data-view-status="busy"] {
    pointer-events: all;
    opacity: 1;
  }
  .doka--edit-status:empty {
    display: none;
  }
  .doka--editor {
    height: 100%;
    width: 100%;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    max-width: 80em;
    max-width: var(--doka-editor--max-width);
    max-height: 60em;
    max-height: var(--doka-editor--max-height);
    opacity: 0;
  }
  .doka--filter-list {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: start;
    align-items: flex-start;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .doka--filter-list li {
    margin: 0.25em 0.5em;
    padding: 0;
  }
  .doka--filter-list li:first-child {
    margin-left: 0;
  }
  .doka--filter-list li:last-child {
    margin-right: 0;
  }
  @media (-ms-high-contrast: none) {
    .doka--filter-list li,
    ::-ms-backdrop {
      -ms-flex: none;
      flex: none;
      width: 4em;
    }
  }
  .doka--filter-tile {
    position: relative;
    border-radius: 0;
    border-radius: var(--doka-filter-tile--radius);
  }
  .doka--filter-tile:before {
    display: block;
    padding-top: 100%;
    content: "";
    height: 0;
  }
  .doka--filter-tile[style*="transform:translate3d(0px, 0px, 0)"] {
    transform: none !important;
  }
  .doka--filter-tile div,
  .doka--filter-tile label {
    transition: box-shadow 0.125s ease-out, color 0.125s ease-out;
  }
  .doka--filter-tile label {
    position: absolute;
    color: hsla(0, 0%, 100%, 0.9);
    color: var(--doka-component--color);
    text-align: center;
    font-size: 0.6875em;
    top: calc(100% + 0.5em);
    width: 100%;
    left: 0;
  }
  .doka--filter-tile > div {
    margin-bottom: 0.5em;
    z-index: 2;
    border-radius: 0;
    border-radius: var(--doka-filter-tile--radius);
    overflow: hidden;
    box-shadow: 0 0 0.75em rgba(0, 0, 0, 0.1);
    pointer-events: none;
  }
  .doka--filter-tile > div canvas {
    z-index: 1;
  }
  .doka--filter-tile > div div {
    z-index: 2;
  }
  .doka--filter-tile canvas,
  .doka--filter-tile div,
  .doka--filter-tile input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-width: auto;
    min-height: auto;
    font-size: 1em;
  }
  .doka--filter-tile input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
    border-radius: 0;
    opacity: 0;
    outline: transparent;
    pointer-events: all;
  }
  .doka--filter-tile input:checked ~ label {
    color: #ffd843;
    color: var(--doka-filter-tile-selected--color);
  }
  .doka--filter-tile input:checked ~ div {
    box-shadow: 0 0.5px 0 1px rgba(0, 0, 0, 0.2), 0 0 0 4px #ffd843;
    box-shadow: 0 0.5px 0 1px rgba(0, 0, 0, 0.2),
      0 0 0 4px var(--doka-filter-tile-selected--color);
  }
  .doka--filter-tile input:focus ~ label {
    color: #ffd843;
    color: var(--doka-filter-tile-selected--color);
  }
  .doka--filter {
    height: calc(100% - 3em);
    width: calc(100% - 2em);
    left: 1em;
    top: 1em;
  }
  .doka--filter .doka--filter-list {
    padding-bottom: 1.5em;
  }
  .doka--filter .doka--filter-scroller {
    margin-bottom: 1em;
  }
  .doka--filter-scroller {
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-x: none;
    -ms-touch-action: pan-x;
    touch-action: pan-x;
    text-align: center;
    pointer-events: all;
    width: 40em;
    margin: 0 auto;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    scrollbar-color: hsla(0, 0%, 100%, 0.2) transparent;
    scrollbar-color: var(--doka-scroller-thumb--color) transparent;
  }
  @supports (-ms-ime-align: auto) {
    .doka--filter-scroller {
      overflow-x: hidden;
    }
  }
  .doka--filter-scroller[data-drag-state="dragging"] input {
    pointer-events: none !important;
  }
  .doka--filter-scroller::-webkit-scrollbar {
    height: 0.375em;
    background-color: transparent;
    background-color: var(--doka-scroller-rail--color);
  }
  .doka--filter-scroller::-webkit-scrollbar-track {
    background: transparent;
  }
  .doka--filter-scroller::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 9999rem;
    border: 3px solid transparent;
    border: 3px solid var(--doka-scroller-rail--color);
    transition: background-color 0.25s ease-in-out;
  }
  @media (pointer: fine) and (hover: hover) {
    .doka--filter-scroller:hover {
      scrollbar-width: thin;
    }
    .doka--filter-scroller:hover::-webkit-scrollbar-thumb {
      background-color: hsla(0, 0%, 100%, 0.2);
      background-color: var(--doka-scroller-thumb--color);
    }
  }
  .doka--filter-list {
    padding: 0 2em;
  }
  .doka--filter-tile {
    -ms-flex: none;
    flex: none;
    width: 4em;
    scroll-snap-align: start;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--filter-tile {
    width: 2.875em;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--filter {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    top: 2em;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--filter .doka--filter-list {
    padding: 0 1em;
    height: 5em;
  }
  .doka--root[data-style-viewport*="x-cramped"] .doka--filter-scroller {
    -ms-scroll-snap-type: proximity;
    scroll-snap-type: proximity;
    -ms-scroll-snap-type: x proximity;
    scroll-snap-type: x proximity;
    scroll-padding: 2em;
    margin-bottom: 0;
  }
  .doka--filter-scroller {
    scrollbar-base-color: #111;
    scrollbar-face-color: #333;
    scrollbar-3dlight-color: #111;
    scrollbar-highlight-color: #111;
    scrollbar-track-color: #111;
    scrollbar-arrow-color: #111;
    scrollbar-shadow-color: #111;
  }
  @supports ((-webkit-mask-image: url("")) or (mask-image: url(""))) and
    (mix-blend-mode: screen) {
    .doka--filter-scroller {
      margin: 0 auto;
      -webkit-mask: linear-gradient(
          90deg,
          transparent,
          #000 2em,
          #000 calc(100% - 2em),
          transparent
        ),
        linear-gradient(0deg, #000 8px, transparent 0);
      mask: linear-gradient(
          90deg,
          transparent,
          #000 2em,
          #000 calc(100% - 2em),
          transparent
        ),
        linear-gradient(0deg, #000 8px, transparent 0);
    }
    .doka--root[data-style-viewport*="x-cramped"] .doka--filter-scroller {
      -webkit-mask: linear-gradient(
        90deg,
        transparent,
        #000 1em,
        #000 calc(100% - 1em),
        transparent
      );
      mask: linear-gradient(
        90deg,
        transparent,
        #000 1em,
        #000 calc(100% - 1em),
        transparent
      );
    }
  }
  .doka--image-gl {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .doka--image-gl canvas {
    position: absolute;
    left: 0;
    top: 0;
    color: #fff;
    color: var(--doka-editor-outline--color);
    background: rgba(0, 0, 0, 0.99);
    background: var(--doka-editor--background-color);
  }
  .doka--image-gl[tabindex="0"] canvas {
    pointer-events: all;
    cursor: move;
  }
  .doka--image-gl:after {
    content: "";
    transition: opacity 0.15s ease-in-out;
    opacity: 0;
    position: absolute;
    left: calc(50% - 1.3em);
    top: calc(50% - 1.3em);
    width: 2.75em;
    height: 2.75em;
    pointer-events: none;
    border-radius: 9999em;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 2em;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd' stroke='rgba(255,255,255,.9)' stroke-width='1.5'%3E%3Crect x='8.5' y='3.5' width='7' height='7' rx='2'/%3E%3Crect x='1.5' y='10.5' width='7' height='7' rx='2'/%3E%3Crect x='8.5' y='10.5' width='7' height='7' rx='2'/%3E%3Crect x='15.5' y='10.5' width='7' height='7' rx='2'/%3E%3C/g%3E%3C/svg%3E");
  }
  .doka--image-gl[data-show-interaction-indicator="true"]:focus:after {
    opacity: 1;
  }
  .doka--button-destroy-shape {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    margin: 0;
    border: none;
    font-family: inherit;
    outline: transparent;
    width: auto;
    height: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 2.5;
    font-weight: 500;
    transition: opacity 0.15s ease-out, background-color 0.25s ease-out,
      color 0.25s ease-out;
    border-radius: 9999em;
    padding: 0 1em;
    font-size: 0.75em;
    opacity: 0;
    color: rgba(0, 0, 0, 0.85);
    color: var(--doka-button-zoom--color);
    background-color: hsla(0, 0%, 100%, 0.85);
    background-color: var(--doka-button-zoom--background);
  }
  .doka--button-destroy-shape span {
    pointer-events: none;
  }
  .doka--button-destroy-shape:focus,
  .doka--button-destroy-shape:hover {
    background-color: hsla(0, 0%, 100%, 0.95);
    background-color: var(--doka-button-zoom-hover--background);
  }
  .doka--button-destroy-shape[data-active="true"] {
    opacity: 1 !important;
  }
  .doka--button-destroy-shape[data-active="false"] {
    opacity: 0 !important;
    pointer-events: none;
  }
  @supports (
    (-webkit-backdrop-filter: var(--doka-effect--frost)) or
      (backdrop-filter: var(--doka-effect--frost))
  ) {
    .doka--button-destroy-shape {
      background-color: hsla(0, 0%, 100%, 0.6);
      background-color: var(--doka-button-zoom-effect--background);
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: var(--doka-effect--frost);
      backdrop-filter: var(--doka-effect--frost);
    }
    .doka--button-destroy-shape:focus,
    .doka--button-destroy-shape:hover {
      background-color: hsla(0, 0%, 100%, 0.75);
      background-color: var(--doka-button-zoom-effect-hover--background);
    }
  }
  .doka--image-markup {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: all;
  }
  .doka--image-markup svg {
    contain: strict;
  }
  .doka--image-markup[data-util="draw"] svg {
    cursor: crosshair;
  }
  .doka--image-markup[data-util="draw"] .doka--shape-group {
    pointer-events: none;
  }
  .doka--image-markup text {
    white-space: pre;
  }
  .doka--image-markup image {
    transition: opacity 0.25s ease-in-out;
  }
  .doka--image-markup input {
    background: transparent;
    position: absolute;
    left: 0;
    top: 0;
    line-height: 1 !important;
    text-align: left !important;
    text-indent: 0 !important;
    border: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    box-shadow: none !important;
    pointer-events: none;
    outline: none;
    color: #fff;
    z-index: 1;
  }
  .doka--image-markup input::-ms-clear {
    display: none;
  }
  .doka--image-markup input:focus {
    pointer-events: all;
  }
  .doka--image-markup[data-active="true"] {
    pointer-events: all !important;
  }
  .doka--manipulator-group {
    pointer-events: none;
  }
  .doka--manipulator-group circle {
    pointer-events: all;
    fill: #2990ff;
    fill: var(--doka-markup-manipulator--fill);
    stroke: #fff;
    stroke: var(--doka-markup-manipulator--stroke);
    stroke-width: 2;
  }
  .doka--manipulator-group path,
  .doka--manipulator-group rect {
    stroke-width: 1.5;
    stroke: hsla(0, 0%, 100%, 0.75);
    stroke: var(--doka-markup-manipulator--line);
  }
  .doka--manipulator-group[is-bright-color="true"] path {
    stroke: rgba(0, 0, 0, 0.75);
    stroke: var(--doka-markup-manipulator--line-inverted);
  }
  .doka--image {
    pointer-events: none;
  }
  .doka--root[data-style-layout-mode~="fullscreen"] {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2147483647;
    opacity: 0;
  }
  .doka--root[data-style-layout-mode~="fullscreen"]:focus {
    outline: transparent;
  }
  .doka--root[data-style-layout-mode~="fullscreen"] .doka--edit-status {
    position: fixed;
  }
  .doka--root[data-style-layout-mode~="fullscreen"][data-style-fullscreen-safe-area="bottom"][data-style-fullscreen="true"] {
    padding-bottom: 40px;
    box-sizing: border-box;
  }
  @media (min-height: 635px) {
    .doka--root[data-style-layout-mode~="fullscreen"][data-style-fullscreen-safe-area="bottom"][data-style-fullscreen="true"] {
      padding-bottom: 80px;
    }
  }
  .doka--parent::-webkit-scrollbar {
    background-color: rgba(0, 0, 0, 0.85);
    background-color: var(--doka-scrollbar-rail--color);
  }
  .doka--parent::-webkit-scrollbar-track {
    background: transparent;
  }
  .doka--parent::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.75);
    background: var(--doka-scrollbar-thumb--color);
    border-radius: 9999rem;
    border: 3px solid rgba(0, 0, 0, 0.85);
    border: 3px solid var(--doka-scrollbar-rail--color);
  }
  @media (min-width: 56rem) and (min-height: 40rem) {
    .doka--root[data-style-layout-mode~="modal"] {
      background: rgba(0, 0, 0, 0.8);
      background: var(--doka-modal--overlay);
      transition: background-color 0.25s ease-in-out;
    }
    .doka--root[data-style-layout-mode~="modal"]:before {
      content: none;
    }
    .doka--root[data-style-layout-mode~="modal"] .doka--editor {
      position: relative;
      overflow: hidden;
      background: #000;
      background: var(--doka-modal--background);
      box-shadow: 0 1em 4em rgba(0, 0, 0, 0.25), 0 0.5em 2em rgba(0, 0, 0, 0.25);
      box-shadow: var(--doka-modal--shadow);
      transition: background-color 0.25s ease-in-out;
      width: 70vw;
      width: var(--doka-modal--width);
      height: 80vh;
      height: var(--doka-modal--height);
      max-width: 56rem;
      max-width: var(--doka-modal--max-width);
      max-height: 40rem;
      max-height: var(--doka-modal--max-height);
    }
    .doka--root[data-style-layout-mode~="modal"] .doka--edit-status {
      position: absolute;
    }
  }
  .doka--root[data-style-layout-mode~="preview"] {
    background: transparent;
  }
  .doka--root[data-style-layout-mode~="preview"]:before {
    content: none;
  }
  .doka--root[data-style-layout-mode~="preview"] .doka--content {
    position: relative;
    z-index: 1;
  }
  .doka--root[data-style-layout-mode~="preview"] .doka--editor {
    max-width: none;
    max-height: none;
  }
  .doka--root[data-style-layout-mode~="preview"] .doka--image-outline,
  .doka--root[data-style-layout-mode~="preview"] .doka--utils {
    display: none;
  }
  .doka--root[data-style-layout-mode~="preview"] .doka--menu {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    pointer-events: none !important;
  }
  .doka--root[data-style-layout-mode~="preview"] .doka--menu .doka--button {
    pointer-events: all;
  }
  .doka--root[data-style-layout-mode~="preview"] .doka--button,
  .doka--root[data-style-layout-mode~="preview"] .doka--crop-rotator {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: var(--doka-effect--frost);
    backdrop-filter: var(--doka-effect--frost);
    box-shadow: 0 0.125em 0.5em rgba(0, 0, 0, 0.325);
  }
  .doka--root[data-style-layout-mode~="preview"]
    .doka--button-app:not(.doka--button-action-confirm) {
    background-color: rgba(0, 0, 0, 0.8);
    background-color: var(--doka-component--background-dark);
  }
  .doka--root[data-style-layout-mode~="preview"]
    .doka--button-app:not(.doka--button-action-confirm):focus,
  .doka--root[data-style-layout-mode~="preview"]
    .doka--button-app:not(.doka--button-action-confirm):hover {
    background-color: rgba(0, 0, 0, 0.9);
    background-color: var(--doka-component--background-darker);
  }
  .doka--root[data-style-layout-mode~="preview"] .doka--processing-indicator p {
    color: hsla(0, 0%, 100%, 0.9);
    color: var(--doka-editor--color);
    background-color: rgba(0, 0, 0, 0.8);
    background-color: var(--doka-component--background-dark);
  }
  .doka--root[data-style-layout-mode~="preview"][data-style-viewport*="x-cramped"]
    .doka--processing-indicator {
    bottom: 0;
  }
  .doka--root[data-style-layout-mode~="preview"] .doka--toolbar {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 !important;
    z-index: 3;
  }
  .doka--root[data-style-layout-mode~="preview"]
    .doka--toolbar
    .doka--button-tool {
    background-color: rgba(0, 0, 0, 0.8);
    background-color: var(--doka-component--background-dark);
    border-radius: 0.25em;
  }
  .doka--root[data-style-layout-mode~="preview"]
    .doka--toolbar
    .doka--button-tool:focus,
  .doka--root[data-style-layout-mode~="preview"]
    .doka--toolbar
    .doka--button-tool:hover {
    background-color: rgba(0, 0, 0, 0.9);
    background-color: var(--doka-component--background-darker);
  }
  .doka--root[data-style-layout-mode~="preview"] .doka--crop-rect {
    mix-blend-mode: difference;
    height: 100%;
    pointer-events: none;
    -webkit-mask: radial-gradient(ellipse closest-side, #000, transparent);
    mask: radial-gradient(ellipse closest-side, #000, transparent);
  }
  .doka--root[data-style-layout-mode~="preview"] .doka--crop-rotator {
    position: absolute;
    bottom: 0;
    left: 12rem;
    right: 12rem;
    border-radius: 9999rem;
    background-color: rgba(0, 0, 0, 0.8);
    background-color: var(--doka-component--background-dark);
  }
  .doka--root[data-style-layout-mode~="preview"] .doka--crop-size {
    transform: none !important;
    right: 1em;
    top: 1em;
    left: auto;
  }
  .doka--root[data-style-layout-mode~="preview"] .doka--crop-rotator-line-mask {
    max-width: 100%;
  }
  .doka--root[data-style-layout-mode~="preview"] .doka--crop-subject {
    margin: 0;
  }
  .doka--root[data-style-layout-mode~="preview"]
    .doka--image-container[style*="opacity:1"]
    ~ .doka--image-overlay {
    background-color: rgba(0, 0, 0, 0.9);
    background-color: var(--doka-component--background-darker);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .doka--root[data-style-layout-mode~="preview"][data-style-viewport~="x-cramped"]
    .doka--crop-rotator {
    display: none;
  }
  .doka--root[data-style-layout-mode~="preview"][data-style-viewport~="x-spacious"]
    .doka--crop-rotator {
    left: 16rem;
    right: 16rem;
  }
  .doka--root[data-style-layout-mode~="preview"] .doka--crop-rect-corner,
  .doka--root[data-style-layout-mode~="preview"] .doka--crop-rect-edge {
    display: none;
  }
  .doka--menu {
    display: -ms-flexbox;
    display: flex;
    box-sizing: border-box;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    z-index: 2;
    position: relative;
    pointer-events: all !important;
    transition: background-color 0.125s ease-in-out, color 0.125s ease-in-out;
    overflow: hidden;
  }
  .doka--menu .doka--button-action-confirm {
    margin-left: auto;
  }
  .doka--root:not([data-style-viewport~="x-cramped"]) .doka--menu {
    height: 4.75em;
    padding: 0 1.25em;
  }
  .doka--root:not([data-style-viewport~="x-cramped"]) .doka--utils {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5em;
    right: 5em;
  }
  .doka--root:not([data-style-viewport~="x-cramped"])
    .doka--button-action-cancel {
    margin-right: 1em;
  }
  .doka--root:not([data-style-viewport~="x-cramped"]) .doka--button-action-reset {
    margin-right: auto;
  }
  .doka--root[data-style-viewport~="flow-controls"]:not([data-style-viewport~="x-cramped"])
    .doka--utils {
    left: 12em;
    left: var(--doka-menu--offset);
    right: 12em;
    right: var(--doka-menu--offset);
  }
  .doka--root[data-style-viewport~="x-cramped"] .doka--menu {
    margin-top: -0.75em;
    height: 3.25em;
  }
  .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
    .doka--utils {
    position: absolute;
    left: 3.25em;
    right: 3.25em;
    top: 0;
    bottom: 0;
  }
  .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
    .doka--menu {
    overflow: hidden;
    margin: 0 1em 1em;
    padding: 0 0.6125em;
    border-radius: 9999em;
    background: hsla(0, 0%, 100%, 0.1);
    background: var(--doka-component--background-dim);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: var(--doka-effect--frost);
    backdrop-filter: var(--doka-effect--frost);
  }
  .doka--root[data-style-viewport~="x-cramped"][data-style-viewport~="multi-util"]
    .doka--button-action-reset {
    position: absolute;
    left: 0.75em;
    top: 0.6875em;
    width: 2.5em;
    height: 2.5em;
  }
  .doka--root[data-view="resize"] .doka--menu {
    background: rgba(0, 0, 0, 0.8) !important;
    background: var(--doka-component--background-dark) !important;
  }
  .doka--root[data-style-viewport~="single-util"][data-style-viewport~="no-flow-controls"]
    .doka--button-action-reset {
    position: absolute;
    top: 0.3125em;
    left: calc(50% - 1.125em);
  }
  .doka--range-input {
    display: block;
  }
  .doka--range-input button {
    position: absolute;
    top: calc(50% + 0.5em);
    height: 1.325em;
    width: 2em;
    bottom: 100%;
    left: calc(50% - 1em);
    padding: 0;
    margin: 0;
    border: none;
    font-size: 1em;
    overflow: hidden;
    color: transparent;
    background: transparent;
    outline: transparent;
  }
  .doka--range-input button:after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: calc(50% - 0.5em);
    left: calc(50% - 0.06125em);
    width: 0.125em;
    height: 0.5em;
    background: hsla(0, 0%, 100%, 0.2);
    background: var(--doka-range-input-recenter--background);
    border-radius: 0.9999em;
    bottom: 0;
  }
  .doka--range-input[data-centered="true"] button:after {
    background: hsla(0, 0%, 100%, 0.2);
    background: var(--doka-range-input-centered--background);
  }
  .doka--range-input input {
    -webkit-appearance: none;
    display: block;
    font-size: 1em;
    width: 100%;
    min-width: auto;
    min-height: auto;
    position: absolute;
    left: 0.5em;
    width: calc(100% - 1em);
    margin: 0;
    padding: 0;
    line-height: 0;
    top: 0;
    height: 100%;
    background: transparent;
    border: none;
    border-radius: 0;
  }
  .doka--range-input input::-webkit-slider-runnable-track {
    background: transparent;
  }
  .doka--range-input input::-webkit-slider-thumb {
    -webkit-appearance: none;
    line-height: 0;
    margin: 0;
    padding: 0;
    border: none;
    width: 0.625em;
    height: 0.625em;
    border-radius: 0.9999em;
    transition: box-shadow 0.25s ease-in-out, background-color 0.25s ease-in-out;
    background: #fff;
    background: var(--doka-range-input-thumb--color);
  }
  .doka--range-input input::-moz-range-thumb {
    line-height: 0;
    margin: 0;
    padding: 0;
    border: none;
    width: 0.625em;
    height: 0.625em;
    border-radius: 0.9999em;
    transition: box-shadow 0.25s ease-in-out, background-color 0.25s ease-in-out;
    background: #fff;
    background: var(--doka-range-input-thumb--color);
  }
  .doka--range-input input::-ms-thumb {
    line-height: 0;
    margin: 0;
    padding: 0;
    border: none;
    width: 0.625em;
    height: 0.625em;
    border-radius: 0.9999em;
    transition: box-shadow 0.25s ease-in-out, background-color 0.25s ease-in-out;
    background: #fff;
  }
  .doka--range-input input::-ms-ticks-before,
  .doka--range-input input::-ms-tooltip {
    display: none;
  }
  .doka--range-input input::-ms-ticks-after {
    display: none;
  }
  .doka--range-input input::-ms-track {
    color: transparent;
    border: none;
    background: transparent;
  }
  .doka--range-input input::-ms-fill-lower,
  .doka--range-input input::-ms-fill-upper {
    background: transparent;
  }
  .doka--range-input input:focus {
    outline: transparent;
  }
  .doka--range-input input:focus::-moz-range-thumb {
    box-shadow: 0 0 0 0.5em hsla(0, 0%, 100%, 0.15);
    box-shadow: 0 0 0 0.5em var(--doka-range-input-thumb-hover--color);
  }
  .doka--range-input input:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 0.5em hsla(0, 0%, 100%, 0.15);
    box-shadow: 0 0 0 0.5em var(--doka-range-input-thumb-hover--color);
  }
  .doka--range-input span:hover input::-moz-range-thumb {
    box-shadow: 0 0 0 0.5em hsla(0, 0%, 100%, 0.15);
    box-shadow: 0 0 0 0.5em var(--doka-range-input-thumb-hover--color);
  }
  .doka--range-input span:hover input::-webkit-slider-thumb {
    box-shadow: 0 0 0 0.5em hsla(0, 0%, 100%, 0.15);
    box-shadow: 0 0 0 0.5em var(--doka-range-input-thumb-hover--color);
  }
  .doka--range-input-inner {
    display: block;
    position: relative;
    -ms-flex: 1;
    flex: 1;
    min-height: 2em;
  }
  .doka--range-input-inner:after {
    content: "";
    position: absolute;
    left: 0.5em;
    right: 0.5em;
    top: calc(50% - 0.125em);
    height: 0.25em;
    background-color: hsla(0, 0%, 100%, 0.2);
    background-color: var(--doka-range-input--background);
    transition: background-color 0.25s ease-in-out;
    border-radius: 0.9999em;
  }
  .doka--resize-form {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    pointer-events: all;
    padding: 1em 0;
    margin: 0;
    height: 2.5em;
  }
  .doka--resize-form fieldset,
  .doka--resize-form legend {
    border: none;
    padding: 0;
    margin: 0;
  }
  .doka--resize-form legend {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
  .doka--resize-form fieldset {
    margin: 0;
    padding: 0;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    max-width: 17em;
  }
  .doka--resize-form .doka--size-input {
    margin: 0;
    width: calc(50% - 1.125em);
  }
  .doka--resize-form .doka--checkable {
    margin: 0 0.325em;
    vertical-align: bottom;
  }
  .doka--resize-form .doka--checkable label {
    padding: 0.3125em 0;
  }
  .doka--resize-form button {
    margin-right: -3.25em;
    margin-left: 1em;
    font-size: 0.875em;
  }
  .doka--resize-form button svg {
    width: 1.25em;
    height: 1.25em;
  }
  .doka--root[data-view="resize"] .doka--resize-form {
    background-color: rgba(0, 0, 0, 0.8);
    background-color: var(--doka-component--background-dark);
  }
  .doka--root[data-style-viewport~="flow-controls"][data-style-viewport~="single-util"]:not([data-style-viewport~="x-cramped"])
    .doka--resize-form {
    margin-top: -4.5em;
    background: none;
  }
  .doka--root[data-style-viewport*="touch"] .doka--resize-form button {
    position: absolute;
    opacity: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }
  .doka--view-stack .doka--resize {
    height: calc(100% - 1em) !important;
  }
  .doka--size-input {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    cursor: text;
  }
  .doka--size-input input::-webkit-inner-spin-button,
  .doka--size-input input::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  .doka--size-input input::-ms-clear {
    display: none;
  }
  .doka--size-input input,
  .doka--size-input label {
    padding: 0.625em 0.5em;
    text-indent: 0.25em;
    font-size: 0.875em;
    font-weight: 400;
    line-height: inherit;
    cursor: inherit;
  }
  .doka--size-input label {
    pointer-events: none;
    position: absolute;
    right: 0;
    cursor: text;
    -ms-flex: 1;
    flex: 1;
    white-space: nowrap;
    text-align: right;
    padding-left: 0;
    padding-right: 0.75em;
  }
  .doka--size-input input,
  .doka--size-input label {
    color: hsla(0, 0%, 100%, 0.9);
    color: var(--doka-editor--color);
  }
  .doka--size-input input {
    -webkit-appearance: none;
    appearance: none;
    -moz-appearance: textfield;
    margin: 0;
    min-height: 0;
    min-width: 7em;
    height: auto;
    width: 100%;
    font-variant-numeric: tabular-nums;
    outline: transparent;
    border-radius: 0.5em;
    box-shadow: none;
    border: none;
    background-color: hsla(0, 0%, 100%, 0.15);
    background-color: var(--doka-component--background);
    transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;
  }
  .doka--size-input input:focus {
    transition: inherit;
    border: inherit;
    background-color: inherit;
    box-shadow: 0 0 0 2px currentColor;
  }
  .doka--size-input input::-webkit-input-placeholder {
    color: hsla(0, 0%, 100%, 0.3) !important;
    color: var(--doka-editor--color-dim) !important;
  }
  .doka--size-input input::-moz-placeholder {
    color: hsla(0, 0%, 100%, 0.3) !important;
    color: var(--doka-editor--color-dim) !important;
  }
  .doka--size-input input::-ms-input-placeholder {
    color: hsla(0, 0%, 100%, 0.3) !important;
    color: var(--doka-editor--color-dim) !important;
  }
  .doka--size-input input::placeholder {
    color: hsla(0, 0%, 100%, 0.3) !important;
    color: var(--doka-editor--color-dim) !important;
  }
  .doka--size-input input:-ms-input-placeholder {
    color: hsla(0, 0%, 100%, 0.3) !important;
    color: var(--doka-editor--color-dim) !important;
  }
  @supports (
    (-webkit-backdrop-filter: var(--doka-effect--frost)) or
      (backdrop-filter: var(--doka-effect--frost))
  ) {
    .doka--size-input input {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: var(--doka-effect--frost);
      backdrop-filter: var(--doka-effect--frost);
    }
  }
  .doka--root {
    box-sizing: content-box;
    position: relative;
    overflow: hidden;
    -ms-touch-action: none;
    touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 100%;
    width: 100%;
    line-height: normal;
    font-size: 16px;
    font-size: var(--doka-font-size);
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica,
      Arial, sans-serif;
    font-family: var(--doka-font-family);
    font-weight: 450;
    text-align: left;
    text-rendering: optimizeLegibility;
    direction: ltr;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    color: hsla(0, 0%, 100%, 0.9);
    color: var(--doka-editor--color);
    background: rgba(0, 0, 0, 0.99);
    background: var(--doka-editor--background-color);
    contain: strict;
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
  }
  .doka--root * {
    box-sizing: inherit;
  }
  .doka--root button {
    box-sizing: border-box;
  }
  .doka--root:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: radial-gradient(
      ellipse at center,
      hsla(0, 0%, 100%, 0.15) 0,
      hsla(0, 0%, 100%, 0) 80%
    );
    background: radial-gradient(
      ellipse at center,
      var(--doka-editor--background-ellipse-inner-color) 0,
      var(--doka-editor--background-ellipse-outer-color) 80%
    );
  }
  .doka--markup {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .doka--markup .doka--toolbar {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 0 auto;
    padding: 1.25em 2em;
  }
  .doka--markup-utils {
    border: none;
    padding: 0;
  }
  .doka--markup-utils ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
  }
  .doka--markup-utils li {
    margin: 0 0.75em;
  }
  .doka--markup-utils input {
    display: none;
  }
  .doka--markup-utils label {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    pointer-events: all;
    height: 2.5em;
  }
  .doka--markup-utils input:checked + label {
    color: #ffd843;
    color: var(--doka-filter-tile-selected--color);
  }
  .doka--toolbar[data-layout="compact"] .doka--markup-utils li {
    margin: 0 0.3125em;
  }
  .doka--markup-tools {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: auto 1em 0;
    padding: 0.3125em 0 0.75em;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: end;
    align-items: flex-end;
    max-height: 3em;
    min-height: 2.0625em;
  }
  .doka--markup-tools[data-active="true"] {
    pointer-events: all !important;
  }
  .doka--markup-tools .doka--button span {
    text-align: left;
  }
  .doka--markup-tools .doka--dropdown > .doka--button {
    color: hsla(0, 0%, 100%, 0.9);
    color: var(--doka-button--color);
    background-color: hsla(0, 0%, 100%, 0.15);
    background-color: var(--doka-button--background);
    border-radius: 9999em;
    line-height: 1.75em;
    margin: 0 0.25em;
    font-size: 0.925em;
  }
  .doka--markup-tools .doka--dropdown > .doka--button > span {
    margin-left: 0.3125em;
  }
  .doka--markup-tools .doka--dropdown {
    margin-top: 0.5em;
  }
  .doka--markup-tools [data-active="false"] > .doka--button {
    display: none;
  }
  .doka--markup-tools .doka--markup-color {
    margin-top: -0.875em;
  }
  .doka--markup-tools .doka--markup-color[data-active="false"] {
    display: none;
  }
  .doka--markup-color {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .doka--markup-color,
  .doka--markup-color ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .doka--markup-color ul {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .doka--markup-color li {
    padding: 0;
    margin: 0 0.125em;
  }
  .doka--markup-color li input {
    display: none;
  }
  .doka--markup-color label {
    display: block;
    overflow: hidden;
    color: transparent;
    width: 1.5em;
    height: 1.5em;
    margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: inherit;
    border-radius: 9999em;
    transition: box-shadow 0.125s ease-out;
    box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    cursor: inherit;
  }
  .doka--markup-color .doka--color-input input[data-selected="true"] + label,
  .doka--markup-color input:checked + label {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.5), 0 0 0 2px #fff;
  }
  .doka--markup-color .doka--color-input {
    margin: 0 0.125em;
  }
  .doka--color-input {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 1.5em;
    height: 1.5em;
    border-radius: 9999em;
    color: hsla(0, 0%, 100%, 0.9);
    color: var(--doka-button--color);
    background-color: hsla(0, 0%, 100%, 0.15);
    background-color: var(--doka-button--background);
  }
  .doka--color-input label {
    overflow: hidden;
    background-image: radial-gradient(
        hsla(0, 0%, 100%, 0.3) 33%,
        hsla(0, 0%, 100%, 0) 36%
      ),
      url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23FECD09' d='M12 0h12v24H12z'/%3E%3Cpath fill='%2366AF01' d='M0 12h24v12H0z'/%3E%3Cpath fill='%237300CD' d='M0 0h12v24H0z'/%3E%3Cpath fill='%23E12224' d='M0 0h12v12H0z'/%3E%3Cpath fill='%23FF7D00' d='M12 12L24 0H12z'/%3E%3Cpath fill='%23B400AA' d='M0 12V0l12 12z'/%3E%3Cpath fill='%23039695' d='M12 24h12L12 12z'/%3E%3Cpath fill='%23465FCD' d='M12 24H0l12-12z'/%3E%3C/g%3E%3C/svg%3E");
  }
  .doka--color-input input {
    position: absolute;
    opacity: 0;
    -webkit-appearance: none;
    border: 0;
    padding: 0;
    margin: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 1em;
    min-width: 0;
    min-height: 0;
  }
  .doka--color-input span {
    position: absolute;
    pointer-events: none;
  }
  .doka--color-input .doka--color-visualizer {
    opacity: 0;
    width: 0.375em;
    height: 0.375em;
    border-radius: 1em;
    box-shadow: 0 0 1px 1px #fff, 0 0 0 1.5px rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
  .doka--color-input .doka--color-visualizer[style] {
    opacity: 1;
  }
  .doka--color-input .doka--color-brightness {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 9999em;
    background: #000;
    box-shadow: inset 0 0 0.75em hsla(0, 0%, 100%, 0.25), 0 0 1px #000;
    opacity: 0;
  }
  .doka--root[data-style-viewport~="x-cramped"] .doka--markup-color {
    width: 100%;
  }
  .doka--status-progress {
    display: none;
    background-color: rgba(0, 0, 0, 0.2);
    background-color: var(--doka-status-bubble--color-dim);
    border-radius: 50%;
    pointer-events: none;
  }
  .doka--status-bubble {
    pointer-events: none;
    will-change: transform;
    text-align: center;
  }
  .doka--status-bubble .doka--status-bubble-inner {
    position: relative;
    display: inline-block;
    color: rgba(0, 0, 0, 0.8);
    color: var(--doka-status-bubble--color);
    background-color: hsla(0, 0%, 100%, 0.75);
    background-color: var(--doka-status-bubble--background);
    box-shadow: 0 0.125em 1em rgba(0, 0, 0, 0.2);
    box-shadow: var(--doka-status-bubble--shadow);
    transition: background-color 0.25s ease-in-out, color 0.25s ease-in-out;
    font-size: 0.875em;
    max-width: 80%;
    margin: 0 auto;
    border-radius: 1em;
    line-height: 1.5;
    text-align: center;
    padding: 0.1875em 1em 0.25em;
  }
  .doka--status-bubble p {
    display: inline;
    margin: 0;
    padding: 0;
  }
  .doka--status-bubble .doka--status-progress:not([data-value="0"]) {
    position: absolute;
    display: block;
    right: 0.35em;
    top: 0.35em;
    width: 1.25em;
    height: 1.25em;
  }
  .doka--status-bubble .doka--status-progress:not([data-value="0"]) + p,
  .doka--status-bubble button + p {
    padding-right: 1.5em;
  }
  .doka--status-bubble button {
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 0;
    border: none;
    font-family: inherit;
    font-size: 1em;
    font-weight: 400;
    outline: transparent;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    pointer-events: all;
    border-radius: 9999em;
    width: 18px;
    height: 18px;
    color: hsla(0, 0%, 100%, 0.75);
    color: var(--doka-status-bubble--background);
    background-color: rgba(0, 0, 0, 0.8);
    background-color: var(--doka-status-bubble--color);
    position: absolute;
    right: 0.375em;
    top: 0.35em;
  }
  .doka--status-bubble button svg {
    width: 0.875em;
    height: 0.875em;
  }
  .doka--status-bubble button:after {
    content: "";
    position: absolute;
    left: -1em;
    right: -1em;
    top: -1em;
    bottom: -1em;
  }
  @supports (
    (-webkit-backdrop-filter: var(--doka-effect--frost)) or
      (backdrop-filter: var(--doka-effect--frost))
  ) {
    .doka--status-bubble-inner {
      -webkit-backdrop-filter: blur(10px);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: var(--doka-effect--frost);
      backdrop-filter: var(--doka-effect--frost);
      background: hsla(0, 0%, 100%, 0.5);
      background: var(--doka-status-bubble-effect--background);
    }
  }
  .doka--toolbar {
    z-index: 3;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 1.25em 0;
    pointer-events: none;
    margin: 0 2em;
  }
  .doka--toolbar > * {
    pointer-events: all;
    margin: 0 0.75em;
    height: 2.5em;
  }
  .doka--toolbar svg {
    display: inline-block;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .doka--toolbar svg * {
    transition: opacity 0.25s ease-out, transform 0.5s ease-out;
  }
  .doka--toolbar .doka--button {
    white-space: nowrap;
    -ms-flex-negative: 0;
    flex-shrink: 0;
  }
  .doka--root[data-style-viewport~="flow-controls"][data-style-viewport~="single-util"]:not([data-style-viewport~="x-cramped"])
    .doka--toolbar {
    margin: -4.875em 11em 0;
  }
  .doka--toolbar[data-layout="compact"] > * {
    margin: 0 0.5em;
  }
  .doka--root[data-style-viewport~="x-cramped"] .doka--toolbar {
    padding: 1em 0;
  }
  .doka--root[data-style-viewport~="x-cramped"] .doka--toolbar > * {
    margin: 0 0.25em;
  }
  .doka--utils {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex: 1;
    flex: 1;
    padding: 0;
    overflow: hidden;
  }
  .doka--utils > * {
    pointer-events: all;
  }
  .doka--utils > * + * {
    margin-left: 1px;
  }
  .implicit {
    position: absolute;
    overflow: hidden;
    height: 1px;
    width: 1px;
    padding: 0;
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    white-space: nowrap;
  }
  .logo {
    margin: 0;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    font-family: Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
      Helvetica, Arial, sans-serif;
    font-weight: 300;
    font-size: 0.875em;
    pointer-events: all;
    color: #1b1718;
    text-align: left;
  }
  .logo a {
    text-decoration: none;
    padding-top: 0.875em;
    padding-bottom: 0.175em;
    color: inherit;
  }
  .logo a:first-of-type {
    padding-left: 4.4125em;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-image: url(/static/assets/doka-logo.svg);
  }
  .logo a:first-of-type > span {
    letter-spacing: 0.06125em;
  }
  .logo a:last-of-type:not(:first-of-type) {
    margin-left: 0.25em;
  }
  body {
    overflow: hidden;
  }
  html {
    background-color: #000;
  }
  .online-editor {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    transition: 1s;
  }
  .doka--edit-status {
    background: none;
    outline: none;
  }
  .doka--root {
    --doka-effect--invert: 100%;
    --doka-color--alpha: #2990ff;
    --doka-color--alpha-dim: #1a80ec;
  }
  .page--header {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    padding: 10vh 0 0;
    text-align: center;
    z-index: 1001;
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.25s;
  }
  .page--header .logo {
    display: inline-block;
    font-size: 1.5em;
  }
  .page--header .logo a:first-of-type {
    padding-left: 4.1em;
  }
  .page--header p {
    font-size: 1.125em;
    color: #777;
  }
  @media (max-height: 36em) {
    .page--header .logo {
      font-size: 1em;
    }
    .page--header p {
      font-size: 0.875em;
    }
  }
  input[type="file"] {
    outline: none;
  }
  .online-editor--intro-panel {
    background: #fff;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    left: calc(50% - 10em);
    top: calc(50% - 8em);
    width: 20em;
    box-sizing: border-box;
    padding: 2em 1.25em 1em;
    border-radius: 1em;
    text-align: center;
    box-shadow: 0 1.25em 1em -1em rgba(0, 0, 0, 0.25),
      0 1em 5em rgba(0, 0, 0, 0.25);
  }
  .online-editor--intro-panel h1 {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin: 1.25em auto;
    transform: scale(0.99);
  }
  .online-editor--intro-panel p {
    margin: 0 auto;
    font-size: 0.9375em;
    max-width: 15em;
  }
  .online-editor--intro-panel p:first-of-type {
    margin-bottom: 1.5em;
  }
  .online-editor--intro-panel p:last-of-type {
    margin: 1em 0 0.5em;
    font-size: 0.75em;
    color: #999;
    max-width: none;
  }
  .online-editor--intro-panel label {
    text-decoration: none;
    font-weight: 400;
    background: linear-gradient(to bottom right, #7c5bf5, #d2413d);
    color: #fff;
    pointer-events: all;
    border-radius: 9999em;
    padding: 0 0.875em;
    line-height: 2;
    white-space: nowrap;
    display: inline-block;
    margin-bottom: 1.5em;
    cursor: pointer;
    box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.1);
  }
  .online-editor--resources {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 1.25em -10%;
  }
  .online-editor--resources li {
    position: relative;
    -ms-flex: 1;
    flex: 1;
    padding: 0;
    margin: 0 -2.5%;
  }
  .online-editor--resources li:first-child {
    transform: rotate(4deg) scale(0.9);
  }
  .online-editor--resources li:nth-child(2) {
    transform: rotate(-4deg) scale(0.8);
    z-index: 3;
  }
  .online-editor--resources li:nth-child(3) {
    transform: rotate(-1deg) scale(0.95);
    z-index: 2;
  }
  .online-editor--resources li:nth-child(4) {
    transform: rotate(4deg) scale(0.85);
  }
  .online-editor--resources li:nth-child(5) {
    transform: rotate(-3deg) scale(0.8);
  }
  .online-editor--resources button {
    display: block;
    width: 100%;
    height: 100%;
    border: none;
    padding: 0 0 100%;
    background: none;
    transform: scale(1);
    pointer-events: all;
    outline: transparent;
  }
  .online-editor--resources button:active,
  .online-editor--resources button:focus {
    transition-duration: 0.25s;
    transform: scale(0.95);
  }
  .online-editor--resources img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1em;
    box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.2);
    border: 3px solid #fff;
    pointer-events: none;
    background: #fff;
  }
  .page--footer {
    opacity: 0;
  }
  .online-editor--intro-panel {
    transform-origin: center center;
  }
  body[data-state="waiting"] .page--footer {
    opacity: 0;
    pointer-events: none;
  }
  body[data-state="waiting"] .page--header {
    opacity: 1;
    transform: translateY(0);
    transition: transform 1s ease-out, opacity 0.5s ease-out 0.5s;
  }
  body[data-state="waiting"] .online-editor--intro-panel {
    opacity: 1;
    transform: scale(1);
    transition: transform 1s ease-out, opacity 0.5s ease-out 0.5s;
  }
  body[data-state="editing"] .page--footer {
    opacity: 1;
  }
  body[data-state="editing"] .page--header {
    pointer-events: none;
    opacity: 0;
    transform: translateY(-1em);
    transition: transform 1s ease-in, opacity 0.25s ease-in 0.125s;
  }
  body[data-state="editing"] .page--header * {
    pointer-events: none !important;
  }
  body[data-state="editing"] .online-editor--intro-panel {
    opacity: 0;
    transform: scale(0.75);
    transition: transform 1s ease-in, opacity 0.25s ease-in 0.125s;
  }
  body[data-state="editing"] .online-editor--intro-panel * {
    pointer-events: none;
  }
  .page--main {
    position: relative;
    z-index: 1;
  }
  .page--footer {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    right: 0;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
    min-height: 4.5em;
    padding: 0 1.5em;
    display: none;
    pointer-events: none;
  }
  @media (min-width: 50em) {
    .page--footer {
      display: -ms-flexbox;
      display: flex;
    }
    .doka--container {
      padding-top: 4.5rem;
      height: calc(100% - 4.5rem);
    }
  }
  .refer {
    display: inline-block;
    color: rgba(0, 0, 0, 0.95);
    background: rgba(0, 0, 0, 0.1);
    padding: 0.125em 0.5em;
    border-radius: 0.25em;
    font-size: 0.875em;
    pointer-events: all;
    text-decoration: none;
  }
  @media (prefers-color-scheme: dark) {
    .doka--root {
      --doka-effect--invert: 0%;
      --doka-color--alpha: #ffd843;
      --doka-color--alpha-dim: #ffc343;
    }
    .page--header,
    footer {
      color: #fff;
    }
    .page--header p {
      color: #bbb;
    }
    .page--header .logo,
    footer .logo {
      color: inherit;
    }
    .page--header .logo a:first-of-type,
    footer .logo a:first-of-type {
      background-image: url(/static/assets/doka-logo-inverted.svg);
    }
    .refer {
      color: hsla(0, 0%, 100%, 0.95);
      background: hsla(0, 0%, 100%, 0.15);
    }
  }
  

.noform-label{
  font-weight: 600;
}
  .w-100-noform{
    width: 100%;
    display: block;
    @include mq(767px){
      width: 100%;
    }
  }

