/* You can add global styles to this file, and also import other style files */

@import "scss/helpers";
@import "scss/pace";
@import "scss/mixins";
@import "scss/theme";
@import "scss/components";
@import "scss/nzOverride";
@import "scss/forms";
@import "scss/colors";
@import "scss/grid";
.TopGroup {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important;
    background-color: white;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
}

.TopGroupButton {
    margin-right: 10px;
}

.SBetween {
    justify-content: space-around;
}

.FormButton {
    width: 100%;
}

.uppercase{
    text-transform: uppercase;
}


/* 
app-layout{
width: 100% !important; 
} */

body,
html {
    display: inherit !important;
    scroll-behavior: smooth;
}

html {
    font-size: 14px;
    /*   height: 100%; */
    letter-spacing: 0.01rem;
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(34, 41, 47, 0);
}

html body {
    /*   height: 100%; */
    background-color: #F8F8F8;
    overflow-x: hidden;
}

html body p {
    line-height: 1.5rem;
}

body {
    margin: 0;
    font-family: 'Montserrat', Helvetica, Arial, serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
    color: #6E6B7B;
    text-align: left;
    background-color: #F8F8F8;
}

:root {
    --blue: #00CFE8;
    --indigo: #6610F2;
    --purple: #7367F0;
    --pink: #E83E8C;
    --red: #EA5455;
    --orange: #FF9F43;
    --yellow: #FFC107;
    --green: #28C76F;
    --teal: #20C997;
    --cyan: #17A2B8;
    --white: #FFFFFF;
    --gray: #B8C2CC;
    --gray-dark: #1E1E1E;
    --primary: #7367F0;
    --secondary: #82868B;
    --success: #28C76F;
    --info: #00CFE8;
    --warning: #FF9F43;
    --danger: #EA5455;
    --light: #F6F6F6;
    --dark: #4B4B4B;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: 'Montserrat', Helvetica, Arial, serif;
    --font-family-monospace: 'Montserrat', Helvetica, Arial, serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: #5E5873;
}

h1,
.h1 {
    font-size: 2rem;
}

h2,
.h2 {
    font-size: 1.714rem;
}

h3,
.h3 {
    font-size: 1.5rem;
}

h4,
.h4 {
    font-size: 1.286rem;
}

h5,
.h5 {
    font-size: 1.07rem;
}

h6,
.h6 {
    font-size: 1rem;
}

.lead {
    font-size: 1.14rem;
    font-weight: 400;
}

.display-1 {
    font-size: 6rem;
    font-weight: 400;
    line-height: 1.2;
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 400;
    line-height: 1.2;
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 400;
    line-height: 1.2;
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 400;
    line-height: 1.2;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #EBE9F1;
}

small,
.small {
    font-size: 0.857rem;
    font-weight: 400;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #FCF8E3;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.blockquote-footer {
    display: block;
    font-size: 0.857rem;
    color: #B9B9C3;
}

.blockquote-footer::before {
    content: '\2014\00A0';
}

.padding-24 {
    padding: 24px;
}

.nav-pos-fix {
    width: calc(100% - 128px) !important;
    position: relative !important;
}

* {
    outline: 0 !important;
}

.floating-nav {
    @include mq(567px) {
        margin-right: 2rem !important;
    }
}

.filepond--credits {
    display: none !important;
}

.d-flex {
    display: flex;
}

::ng-deep {
    html {
        height: unset !important;
    }
}
