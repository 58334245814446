

nz-select-item{
    margin: 7px;
    &:focus{
        background-color: red !important;
        background: red !important;
        nz-select-placeholder{
            padding: 30px !important
        }
    }
}

textarea {
    @extend .vueInpClass;
}


.max-250{
    max-width: 250px;
}

.c-pointer{
    cursor: pointer;
}


nz-select , nz-select-top-control{
    outline: 0 !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
   // -webkit-text-security: disc !important;
    cursor: text;
    font: 400 13.3333px Arial;
  //  height: 2.714rem;
 //   padding: 0.438rem 1rem !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
    color: #6E6B7B;
    background-color: #FFFFFF;
    background-clip: padding-box;
 //   border: 1px solid #D8D6DE;
    border-radius: 0.357rem;
    box-shadow : unset !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
   
     &::placeholder{
        transition: all 0.3s;
        color : rgb(205, 203, 209)
  
      } 
      &:focus{
        box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%) !important;
        border: 1px solid var(--primary);
         &::placeholder{
          padding-left: 10px;
         }
      } 
}

nz-select-top-control{
    padding-left: 10px !important;
}

.vueInpClass{
    outline: 0 !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
   // -webkit-text-security: disc !important;
    cursor: text;
    font: 400 13.3333px Arial;
    height: 2.714rem;
    padding: 0.438rem 1rem !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.45;
    color: #6E6B7B;
    background-color: #FFFFFF;
    background-clip: padding-box;
    border: 1px solid #D8D6DE;
    border-radius: 0.357rem;
    box-shadow : unset !important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
   
     &::placeholder{
      transition: all 0.3s;
      color : rgb(205, 203, 209)

    } 
    &:focus{
      box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%) !important;
      border: 1px solid var(--primary);
       &::placeholder{
        padding-left: 10px;
       }
    }
    &:hover{
        border: 1px solid var(--primary);
    }
}


.ant-pagination-item-link{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}



.table-action-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
}

.t-exp-item{
    display: flex;
    align-items: center;
    i{
        font-size: 16px;
    }
}

// ant popover
.ant-popover-buttons{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ant-btn-primary{
        height: 30px;
        background: var(--warning);
    }
}

.btn-hovered{
        box-shadow: 0 8px 25px -8px #7367f0;
        &:hover{
            box-shadow: unset !important;
        }
}

.right-aligned-flex{
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: flex-end;
}


// select
.ant-select-focused{
  box-shadow:   0 3px 10px 0 rgb(34 41 47 / 10%) !important;
}


.ant-steps-icon{
    vertical-align: text-bottom !important;
}

.ant-tag{
    display: flex;
    align-items: center;
}


