// our mixins
 @mixin easing($arg){
    -webkit-transition: all $arg+ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
    -moz-transition: all $arg+ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
      -o-transition: all $arg+ms cubic-bezier(0.420, 0.000, 0.580, 1.000);
         transition: all $arg+ms cubic-bezier(0.420, 0.000, 0.580, 1.000); /* ease-in-out */
  }

  @mixin mq($arg){
   @media screen and (max-width: $arg){
       @content;
   }
}
