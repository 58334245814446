
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
@border-radius-base :0.358rem; 
@body-background: #fff;
// Base background color for most components
@component-background: #fff;
// Popover background color
@popover-background: @component-background;
@popover-customize-border-color: @border-color-split;
@font-family:  'Montserrat', Helvetica, Arial, serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
@code-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
@text-color: fade(@black, 85%);
@text-color-secondary: fade(@black, 45%);
@text-color-inverse: @white;
@icon-color: #6E6B7B; // inherit;
@icon-color: #6E6B7B; // inherit;
@icon-color-hover: fade(@black, 75%);

// -------- Colors -----------
@primary-color: #7367F0;
@info-color: #00cfe8;
@success-color: #28c76f;
@processing-color: @blue-6;
@error-color: #ea5455;
@highlight-color: @red-5;
@warning-color: @gold-6;
@normal-color: #d9d9d9;
@white: #fff;
@black: #000;

@primary-color : #7367F0;

@menu-inline-toplevel-item-height: 40px;
@menu-item-height: 40px;
@menu-item-group-height: @line-height-base;
@menu-collapsed-width: 80px;
@menu-bg: @component-background;
@menu-popup-bg: @component-background;
@menu-item-color: @text-color;
@menu-highlight-color: unset; // @primary-color;
@menu-highlight-danger-color: @error-color;
@menu-item-active-bg: unset; // @primary-1; Nav menu için düzenlendi
@menu-item-active-danger-bg: @red-1;
@menu-item-active-border-width: 0px;
@menu-item-group-title-color: @text-color-secondary;
@menu-item-vertical-margin: 7px;
@menu-item-font-size: @font-size-base;
@menu-item-boundary-margin: 10px;
@menu-item-padding: 0 20px;
@menu-horizontal-line-height: 46px;
@menu-icon-margin-right: 10px;
@menu-icon-size: @menu-item-font-size;
@menu-icon-size-lg: @font-size-lg;
@menu-item-group-title-font-size: @menu-item-font-size;

// todo buttons
// Buttons
@btn-font-weight: 400;
@btn-border-radius-base: @border-radius-base;
@btn-border-radius-sm: @border-radius-base;
@btn-border-width: 0px ;// @border-width-base;
@btn-border-style: @border-style-base;
@btn-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
@btn-primary-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
@btn-text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);

@btn-primary-color: #fff;
@btn-primary-bg: @primary-color;

@btn-default-color: @text-color;
@btn-default-bg: @component-background;
@btn-default-border: @border-color-base;

@btn-danger-color: #fff;
@btn-danger-bg: @error-color;
@btn-danger-border: @error-color;

@btn-disable-color: @disabled-color;
@btn-disable-bg: @disabled-bg;
@btn-disable-border: @border-color-base;

@btn-default-ghost-color: @component-background;
@btn-default-ghost-bg: transparent;
@btn-default-ghost-border: @component-background;

@btn-font-size-lg: @font-size-lg - 1px;
@btn-font-size-sm: @font-size-base - 1px;
@btn-padding-horizontal-base: @padding-md - 1px;
@btn-padding-horizontal-lg: @btn-padding-horizontal-base + 7px;
@btn-padding-horizontal-sm: @padding-xs - 1px;

@btn-height-base: @height-base;
@btn-height-lg: @height-lg;
@btn-height-sm: @height-sm;

@btn-line-height: @line-height-base;

@btn-circle-size: @btn-height-base;
@btn-circle-size-lg: @btn-height-lg;
@btn-circle-size-sm: @btn-height-sm;

@btn-square-size: @btn-height-base;
@btn-square-size-lg: @btn-height-lg;
@btn-square-size-sm: @btn-height-sm;
@btn-square-only-icon-size: @font-size-base + 2px;
@btn-square-only-icon-size-sm: @font-size-base;
@btn-square-only-icon-size-lg: @btn-font-size-lg + 2px;

@btn-group-border: @primary-5;

@btn-link-hover-bg: transparent;
@btn-text-hover-bg: rgba(0, 0, 0, 0.018);



// Input
// ---
@input-height-base: @height-base + 10px;
@input-height-lg: @height-lg;
@input-height-sm: @height-sm;
@input-padding-horizontal: @control-padding-horizontal - 1px;
@input-padding-horizontal-base: 0;// @input-padding-horizontal;
@input-padding-horizontal-sm: @control-padding-horizontal-sm - 1px;
@input-padding-horizontal-lg: @input-padding-horizontal;
@input-padding-vertical-base: 0 * max(
  (round(((@input-height-base - @font-size-base * @line-height-base) / 2) * 10) / 10) -
    @border-width-base,
  3px
);
@input-padding-vertical-sm: max(
  (round(((@input-height-sm - @font-size-base * @line-height-base) / 2) * 10) / 10) -
    @border-width-base,
  0
);
@input-padding-vertical-lg: (
    ceil(((@input-height-lg - @font-size-lg * @line-height-base) / 2) * 10) / 10
  ) - @border-width-base;
@input-placeholder-color: hsv(0, 0, 75%);
@input-color: @text-color;
@input-icon-color: @input-color;
@input-border-color: @border-color-base;
@input-bg: @component-background;
@input-number-hover-border-color: @input-hover-border-color;
@input-number-handler-active-bg: #f4f4f4;
@input-number-handler-hover-bg: @primary-5;
@input-number-handler-bg: @component-background;
@input-number-handler-border-color: @border-color-base;
@input-addon-bg: @background-color-light;
@input-hover-border-color: @primary-5;
@input-disabled-bg: @disabled-bg;
@input-outline-offset: 0 0;
@input-icon-hover-color: fade(@black, 85%);
@input-disabled-color: @disabled-color;



/* ::ng-deep{
  .abtn{
    color: #fff !important;
    background: red !important;
    border-top : 10px solid green !important;
    .anticon{
      color: #fff;
    }
  }
} */

// button loding fix
.@{btn-prefix-cls} {
  display: flex;
  align-items: center;
  justify-content: center;
   > i {
    color: #fff !important
  } 
}





// Checkbox
@checkbox-size: 18px;
@checkbox-color: @primary-color;
@checkbox-check-color: #fff;
@checkbox-check-bg: @checkbox-check-color;
@checkbox-border-width: @border-width-base;
@checkbox-group-item-margin-right: 8px;
.ant-checkbox + span {
     color: #5E5873;
     font-size: 1rem;
  &:empty {
    display: none;
  }
}

html{
  height: auto !important;
  background : #f0f2f5 !important;
}


/* .cdk-global-scollblock{
  height: 5000px !important;
  min-height: 15000px !important;
}



/deep/ .cdk-global-scrollblock {
    position: static;
    overflow: hidden !important;
} */