::ng-deep{
 
    .vueInp{
      height: 38px;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.45;
     // -webkit-text-security: disc !important;
      cursor: text;
      font: 400 13.3333px Arial;
      height: 2.714rem;
      padding: 0.438rem 1rem !important;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.45;
      color: #6E6B7B;
      background-color: #FFFFFF;
      background-clip: padding-box;
      border: 1px solid #D8D6DE;
      border-radius: 0.357rem;
      box-shadow : unset !important;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
     
       &::placeholder{
        transition: all 0.3s;
      } 
      &:focus{
        box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%) !important;
         &::placeholder{
          padding-left: 10px;
         }
      }
  
    }
    .vueInpGr{
      box-shadow : unset !important;
      .vueInp{
        border-top-right-radius: unset;
      border-bottom-right-radius: unset;
      }
      span.ant-input-suffix{
        width: 34px !important;
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding-right: 5px;
      }
    }
  }
  


  .pos-r{
    position: relative;
  }

.is-invalid{
  border: 1px solid #ec2142;
  border-color: red !important;
}
.invalid-feedback{
   position: absolute;
   top: 95%;
   width: 100%;
   text-align: right;
   display: block;

}



