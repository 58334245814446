@import "./mixins";
// responsive utilities

.only-desktop{
    display: auto;
    @include mq(1023px){
        display: none !important;
    }
}

.only-mobile{
    display: none;
    @include mq(1023px){
        display: unset !important;
    }
}

.flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .pos-r{
    position: relative;
  }

.is-invalid{
  border: 1px solid #ec2142;
}
.invalid-feedback{
   position: absolute;
   top: 95%;
   width: 100%;
   text-align: right;
   display: block;

}
